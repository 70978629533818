import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        arr: [],
        username: " ",
        password: " ",
        loginName: "",
        checkOpd: true
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})

