import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import uu from '../views/sy/user.vue'
import Login from '../views/Login.vue'
import User from '../views/kyxm/user.vue'
import Class from '../views/kypt/class.vue'
import XueJi from '../views/hetong/xuejiguanli.vue'
import Score from '../views/kycg/score.vue'
import huojiang from "../views/kycg/huojiang.vue";
import jl from "../views/kycg/jl.vue";
import kecheng from "../views/kycg/kecheng.vue";
import rz from "../views/kycg/rz.vue";
import yy from "../views/kycg/yy.vue";
import zh from "../views/kycg/zh.vue";
import zhuanli from "../views/kycg/zhuanli.vue";
import HistoricalAchievement from "../views/kycg/HistoricalAchievement.vue";
import Job from '../views/sjk/jobs.vue'
import xitongrz from '../views/sys/xitongrz.vue'
import LoginRz from '../views/sys/loginrz.vue'
import KeChengTiXi from '../views/kyxm/kechengtixi.vue'
import Collage from '../views/kyxm/collage.vue'
import Role from '../views/kyxm/role.vue'

import Department from '../views/kyxm/department.vue'
import Grade from "../views/kypt/grade.vue";

import Hgrade from "../views/kypt/Hgrade.vue";

import cj from '../views/rl/cj.vue'
import hz from '../views/rl/hz.vue'

import jb from '../views/xs/jb.vue'
import hjmessage from '../views/xs/hjmessage.vue'
import kjlw from '../views/xs/kjlw.vue'
import xslw from '../views/xs/xslw.vue'
import ds from '../views/xs/ds.vue'

import zs from '../views/zs/zs.vue'

//zb
import bs from "../views/zb/bs.vue";
import cre from "../views/zb/cre.vue";
import jd from '../views/zb/jd.vue'
import rk from '../views/zb/rk.vue'
import wj from '../views/zb/wj.vue'
import xm from '../views/zb/xm.vue'
import zy from '../views/zb/zy.vue'

import loginuser from '../views/shme/loginuser.vue'
import xmsh from '../views/shme/xmsh.vue'



Vue.use(VueRouter)

const routes = [{
		path: '/home',
		component: Home,
		redirect: '/home',
		children: [{
				path: '/uu',
				name: 'uu',
				component: uu,
			},
			{
				path: '/1-1-1',
				name: 'Role',
				component: Role
			},
			{
				path: '/1-1-2',
				name: 'User',
				component: User
			},
			{
				path: '/1-1-3',
				name: 'KeChengTiXi',
				component: KeChengTiXi
			},
			{
				path: '/1-1-4',
				name: 'Collage',
				component: Collage
			},
			{
				path: '/1-1-5',
				name: 'Department',
				component: Department
			},

			{
				path: '/1-2',
				name: 'XueJi',
				component: XueJi
			},
			{
				path: '/1-3-2',
				name: 'Score',
				component: Score
			},

			{
				path: '/1-3-1',
				name: 'HistoricalAchievement',
				component: HistoricalAchievement
			},
			{
				path: '/1-3-3',
				name: 'kecheng',
				component: kecheng
			},
			{
				path: '/1-3-4',
				name: 'huojiang',
				component: huojiang
			},
			{
				path: '/1-3-5',
				name: 'zhuanli',
				component: zhuanli
			},
			{
				path: '/1-3-6',
				name: 'yy',
				component: yy
			},
			{
				path: '/1-3-7',
				name: 'zh',
				component: zh
			},
			{
				path: '/1-3-8',
				name: 'jl',
				component: jl
			},
			{
				path: '/1-3-9',
				name: 'rz',
				component: rz
			},

			{
				path: '/1-4-1',
				name: 'Class',
				component: Class
			},

			{
				path: '/1-4-2',
				name: 'Grade',
				component: Grade
			},
			{
				path: '/1-4-3',
				name: 'Hgrade',
				component: Hgrade
			},


			{
				path: '/1-5-1',
				name: 'cj',
				component: cj
			},
			{
				path: '/1-5-2',
				name: 'hz',
				component: hz
			},

	
			{path: '/1-6-1',name: 'jb',component: jb},
			{path: '/1-6-2',name: 'hjmessage',component: hjmessage},
			{path: '/1-6-3',name: 'kjlw',component: kjlw},
			{path: '/1-6-4',name: 'xslw',component: xslw},
			{path: '/1-6-5',name: 'ds',component: ds},


			{path: '/1-7',name: 'Job',component: Job},
			
			{path: '/1-8-1',name: 'LoginRz',component: LoginRz,},
	
			{path: '/1-8-2',name: 'xitongrz',component: xitongrz},


			{path: '/1-9',name: 'zs',component: zs},

			{path: '/1-10-1',name: 'xm',component: xm},
			{path: '/1-10-2',name: 'rk',component: rk},
			{path: '/1-10-3',name: 'jd',component: jd},
			{path: '/1-10-4',name: 'zy',component: zy},
			{path: '/1-10-5',name: 'bs',component: bs},
			{path: '/1-10-6',name: 'cre',component: cre},
			{path: '/1-10-7',name: 'wj',component: wj},
			{path: '/11',name: '11',	component: uu},
			{path: '/loginuser',name: 'loginuser',component: loginuser},
			{path: '/xmsh',name: 'xmsh',component: xmsh},
		]
	},
	{
	    path: "/",
	    name: 'login',
	    component: Login
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router