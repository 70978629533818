<template>
  <div class="login">
    <div></div>
    <!-- login_card -->
    <el-card class="login_card">
      <el-image :src="logo_url" class="logo_image" fit="cover"></el-image>
      <p class="login_title">登 录</p>
      <p class="login_desc">欢迎登录管理系统</p>
      <el-form ref="ruleForm" :model="form" :rules="rules">
        <el-form-item prop="username">
          <el-input
              v-model="form.loginname"
              placeholder="请输入管理员账号"
              prefix-icon="el-icon-user"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              v-model="form.loginpass"
              placeholder="请输入管理员密码"
              prefix-icon="el-icon-lock"
              type="password"
              @keyup.enter.native='submitForm'
          />
        </el-form-item>
        <el-form-item>
          <el-button
              :loading="loginLoading"
              style="background: #72a18a; color: white"
              @click="submitForm"
          >登录
          </el-button
          >
          <el-button
              :loading="loginLoading"
              style=" width:100px;background: white; color: black;border:white 1px solid;margin-left: -2px"
              @click="resForm"
          >重置
          </el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      arr: [],
      logo_url: require("../img/微信图片_20240801162041-removebg-preview.png"),
      form: {
        loginname: "",
        loginpass: ""
      },
      userNames: "",
      ipAddress: "",
      csip:"",
      loginLoading: false,
      rules: {
        loginname: [
          {required: true, message: "请输入管理员账号", trigger: "blur"},
        ],
        loginpass: [
          {required: true, message: "请输入管理员密码", trigger: "blur"},
        ],
      },
    };
  },
  created() {

  },
  methods: {
    //重置按钮
    resForm(){
      this.form = {
        loginname: "",
        loginpass: ""
      }
    },

    //登录接口
    submitForm() {
      if (this.form.loginname == "" || this.form.loginpass == "") {
        this.$message.warning("用户名和密码不能为空")
      } else {
        axios.post("/huser/login", {
          
            name: this.form.loginname,
            password: this.form.loginpass
          
        }).then((res) => {
			console.log(res.data)
			console.log(res.data.user)
          sessionStorage.setItem("uid",res.data.user.id)

          if (res.data == "") {
            this.$message.error("用户名或密码错误")
          } else {
            sessionStorage.setItem("satoken",res.data.tokenvalue)
            sessionStorage.setItem("name", this.form.loginname)
			console.log(sessionStorage.getItem("name"))
            this.$store.state.password = this.form.loginpass
            this.$message.success("登录成功")
            
			this.$router.push({
			  path: '/11'
			})
          }
        }).catch(error=>{
          this.$message.error("登录账号异常")
        })
      }
    },

    /*获取ip地址*/
   

    //获取登录时时间
   

    //登录日志


  },

};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: 100% 100%;
  background-color: #b4d8d9;
}

.login_card {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 30%;
  min-width: 300px;
  height: 480px;
  min-height: 450px;
  border-radius: 10px;
  text-align: center;
}

.logo_image {
  width: 80px;
  height: 80px;
  margin-top: 20px;
}

.login_title {
  font-size: 25px;
  font-weight: bold;
}

.login_desc {
  letter-spacing: 8px;
  color: #999a9a;
}

.el-button {
  width: 100%;
}

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  color: rgb(183, 181, 181);
}

.wechat_logo {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 100px;
  right: 20px;
}

.home_logo {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 40px;
  right: 20px;
}

.juejin_img,
.offical_img {
  width: 180px;
  height: 200px;
  border-radius: 10px;
  position: fixed;
  right: 80px;
  bottom: 20px;
  display: none;
}

.wechat_logo:hover,
.home_logo:hover {
  border-radius: 50%;
  box-shadow: 0 3px 15px 3px rgba(237, 229, 229, 0.5);
}

.wechat_logo:hover + .offical_img {
  display: block;
}

.home_logo:hover + .juejin_img {
  display: block;
}

.footer:hover {
  color: white;
}
</style>