<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
</style>
<template>
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          <!-- <el-button style="height: 38px" type="primary"
                     >导出
          </el-button> -->
		  
          <el-table :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;">
            <el-table-column align="center" label="编号" type="index" width="50"></el-table-column>
			<el-table-column align="center" label="年度" prop="time" width="100"></el-table-column>      
            <el-table-column align="center" label="项目编号" prop="bh" width="120px"> </el-table-column>
            <el-table-column align="center" label="项目名称" prop="xmName"></el-table-column>       
			<el-table-column align="center" label="项目组负责人名称" prop="name" width="150px"></el-table-column>      
			<el-table-column align="center" label="项目来源" prop="lyname"></el-table-column>      
			<el-table-column align="center" label="项目级别" prop="level" width="100px"></el-table-column>      
			<el-table-column align="center" label="项目类型" prop="typename" width="100px"></el-table-column>      
			<el-table-column align="center" label="立项部门" prop="source"></el-table-column>       
			<el-table-column align="center" label="项目起止时间" prop="xmDuration"></el-table-column>

            <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
                <el-button @click="handleupdateup(scope.row.id)" class="ProjectInfo" type="danger">修改状态</el-button>
				<el-button @click="handleupdate(scope.row.bh)" class="ProjectGrod" type="danger" disabled>项目组成员</el-button>
				
              </template>
            </el-table-column>
          </el-table>
		
		
		
		<el-dialog
		  title="审核"
		  :visible.sync="upht"
		  width="55%"
		  center>
		<el-form ref="form" :model="form" label-width="80px">
		  <el-form-item label="审核">
		    <el-radio-group v-model="form.resource">
		      <el-radio label="1" >审核通过</el-radio>
		      <el-radio label="2" >审核不通过</el-radio>
		    </el-radio-group>
		  </el-form-item>
		  <el-form-item>
		    <el-button type="primary" @click="onSubmit">立即创建</el-button>
		    
		  </el-form-item>
		</el-form>
		</el-dialog>
		
		
		
        </el-card>
      </div>
    </el-col>
	
	
 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
    formLabelWidth: "12",
	tableData:[],
	aa:0,
	upht:false,
	  form: {
	          resource: '', 
			  id:''
	        }
    };
  },
  created() {
    this.sele();
  },
  methods: {
		//点击修改出现查询弹窗
	  handleupdateup(id){
		  this.upht=true
		  
		  axios.get('/form/getOneP'+"?id="+id).then((res)=>{
		  	console.log(id);
			this.form.id=id,
		  	// this.form.resource=res.data.audit;
		  	console.log(res.data);	
		  })
	  },
	  
	onSubmit() {
	      console.log("Submitting form...");
	      console.log(this.form.resource); // 应该能正确打印出选中的值
		  this.upht=false
		 axios.put(`form/upsh?id=${this.form.id}&audit=${encodeURIComponent(this.form.resource)}`).then((res) => {
		     console.log(res.data);
		     if (res.data.code == 0) {
		         this.$message.success(res.data.message);
		         this.sele();
		     } else {
		         this.$message.error(res.data.message);
		     }
		 });

	    },
	  
	
	  
	
	 
	  
	  
	  
	  
	  
	  
	  sele(){
		  axios.get('/form/getaudit',{
			  params:{
				  audit: this.aa,  
			  }
		  }).then((res) => {
			  this.tableData = res.data
		  });
	  },
	  
	  

	 


	  


	  

	
		
  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>