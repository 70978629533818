<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
	.ProjectInfo1{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod2{
		width: 120px;
		/* float: right; */
	}
</style>
<template><!-- 变更 -->
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          
<el-button style="height: 38px" type="primary" @click="dc">导出</el-button>
		  
		   <el-descriptions-item>
		        <template slot="label">
		          <i class="el-icon-user"></i>
		          用户名
		        </template>
		        kooriookami
		      </el-descriptions-item>
		  
		  
          <el-table :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;"  ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
					<el-table-column type="selection"  width="40px"></el-table-column>
            <el-table-column align="center" label="项目名称" prop="xmName" width="100px"></el-table-column>      
            <el-table-column align="center" label="课题名称" prop="ktName" width="100px"> </el-table-column>
            <el-table-column align="center" label="课题编号" prop="ktBh"width="120px"></el-table-column>       
			<el-table-column align="center" label="课题承担单位(甲方)" prop="ktJ" width="150px"></el-table-column>      
			<el-table-column align="center" label="课题协作单位(乙方)" prop="ktY"width="150px"></el-table-column>      
			<el-table-column align="center" label="课题协作单位(丙方)" prop="ktB" width="150px"></el-table-column>      
<!-- 			<el-table-column align="center" label="起止时间" prop="starterEnd" width="150px"></el-table-column>      -->
			<el-table-column align="center" label="拨出经费" prop="bcJf"></el-table-column>
			<el-table-column align="center" label="拨入经费" prop="brJf"></el-table-column>
			<el-table-column align="center" label="承担单位伦理批件号" prop="cd"></el-table-column>
			<el-table-column align="center" label="协作单位伦理批件号" prop="xz"></el-table-column>
			

            <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
				  <div>
					 <el-button @click="handleupdateup(scope.row.id)" class="ProjectInfo" type="danger">合同信息</el-button>
					 <el-button @click="handleupdate(scope.row.bh)" class="ProjectGrod" type="danger" disabled>添加</el-button>  
				  </div>
				  <div style="margin-top: 10px;">
				  	<el-button @click="upmessageb(scope.row)" class="ProjectInfo1" type="danger">编辑信息</el-button>
				  	<el-button @click="open(scope.row.id)" class="ProjectGrod2" type="danger">删除</el-button>
				  </div>
              </template>
            </el-table-column>
          </el-table>
		  <div style="margin-top: 20px">
		  	<el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
		  	 <el-button @click="toggleSelection()">取消选择</el-button>
		  </div>
		<!--分页组件-->
		<el-pagination :current-page="currentPage" :page-size="pagesize" :page-sizes="[5, 10, 20, 50]"
		               :total="total" class="pagestrip" layout="total, sizes, prev, pager, next, jumper"
		               style="margin-top: 20px; float:right" @size-change="handleSizeChange" @current-change="handleCurrentChange">
		</el-pagination>
		  
		  
        </el-card>
      </div>
    </el-col>
	
	
	<el-dialog
	  title="合同管理"
	  :visible.sync="dialogFormVisiblegetup"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="项目名称:" :label-width="formLabelWidth">
				   <el-input v-model="form.xmName" autocomplete="off"></el-input>
				 </el-form-item>
	  			  <el-form-item label="课题名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ktName" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="课题编号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ktBh" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="课题承担单位(甲方):" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ktJ" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="课题协作单位(乙方):" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ktY" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="课题协作单位(丙方):" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ktB" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="开始时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.starterTime" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="结束时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.endTime" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="拨出经费:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.bcJf" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="拨入经费:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.brJf" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="承担单位伦理批件号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.cd" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="协作单位伦理批件号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.xz" autocomplete="off"></el-input>
	  			  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="handleupqx()">取 消</el-button>
	  </span>
	</el-dialog>
	
	
	<el-dialog
	  title="编辑合同管理"
	  :visible.sync="upht"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小--> 
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="项目名称:" :label-width="formLabelWidth">
				   <el-input v-model="form.xmName" autocomplete="off"></el-input>
				 </el-form-item>
	  			  <el-form-item label="课题名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ktName" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="课题编号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ktBh" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="课题承担单位(甲方):" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ktJ" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="课题协作单位(乙方):" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ktY" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="课题协作单位(丙方):" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ktB" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="开始时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.starterTime" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="结束时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.endTime" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="拨出经费:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.bcJf" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="拨入经费:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.brJf" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="承担单位伦理批件号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.cd" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="协作单位伦理批件号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.xz" autocomplete="off"></el-input>
	  			  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="handleupqx()">取 消</el-button>
	    <el-button type="primary" @click="hanleupqd()">确 定</el-button>
	  </span>
	</el-dialog>

 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
		//分页
	currentPage:1,
	pagesize:5,
	total:0,
    formLabelWidth: "130px",//控制每一个名称加输入框的宽度
    tableData: [],
	multiplerTable:[],
	dialogFormVisiblegetup: false,//编辑弹窗是否可见false 不可见 true 可见
	upht:false,
	radio: 0,
	form: {},
    };
  },
  created() {
    this.sele();
  },
  methods: {
	  //导出
	  dc(){
	  		 axios({
	  		        url: '/ht/exportZzToExcel',
	  		        method: 'GET',
	  		        responseType: 'blob'
	  		      })
	  		      .then(response => {
	  		        const url = window.URL.createObjectURL(new Blob([response.data]));
	  		        const link = document.createElement('a');
	  		        link.href = url;
	  		        link.setAttribute('download', '合同信息.xlsx');
	  		        document.body.appendChild(link);
	  		        link.click();
	  		        document.body.removeChild(link);
	  		      })
	  		      .catch(error => {
	  		        // 处理错误
	  		      });
	  	  },
	  
	  	  toggleSelection(rows) {
	  	         if (rows) {
	  	           rows.forEach(row => {
	  	             this.$refs.multipleTable.toggleRowSelection(row);
	  	           });
	  	         } else {
	  	           this.$refs.multipleTable.clearSelection();
	  	         }
	  	       },
	  	   handleSelectionChange(val) {
	  	     this.multipleSelection = val;
	  	   },
	  
	 //分页条
	 handleSizeChange(val) {
	   this.pagesize = val;
	   this.sele();
	 },
	 handleCurrentChange(val) {
	   this.currentPage = val;
	   this.sele();
	 }, 
	  
	  sele(){
		  axios.get('ht/hetongAll',{
			  params:{
				  currentPage: this.currentPage,
				  pageSize: this.pagesize,
			  }
		  }).then((res) => {
			  this.tableData = res.data.list
			  this.total = res.data.total
			  console.log(res.data.total)
		  });
	  },
	  
	 
	  //弹出编辑窗口
	  handleupdateup(id){
	  	this.dialogFormVisiblegetup=true;
	  	axios.get('/ht/hetongone'+"?id="+id).then((res)=>{
	  		console.log(id);
	  		this.form=res.data;
	  		console.log(res.data);	
	  	})
	  	
	  },
	  //弹出合同编辑
	  upmessageb(row){
		  this.upht=true
		  var id=row.id
		  axios.get('/ht/hetongone'+"?id="+row.id).then((res)=>{
		  	console.log(id);
		  	this.form=res.data;
		  	console.log(res.data);	
		  })
	  },
	  
	  //确定编辑
	  hanleupqd(){
	  	this.upht=false;
	  	axios.put('ht/upht',this.form).then((res)=>{
			console.log(res.data)
	  		if(res.data.code==0){
	  			this.$message.success(res.data.message);
	  			this.sele();
	  		}else{
	  			this.$message.error(res.data.message);
	  		}
	  	})
	  	
	  },
	  //取消编辑窗口
	  handleupqx(){
	  	this.dialogFormVisiblegetup=false;
	  },
	  
	  //是否删除
	  
	  open(id) {
	    this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
	      confirmButtonText: '确定',
	      cancelButtonText: '取消',
	      type: 'warning'
	    }).then(() => {
	      axios.get('/ht/delht?id='+id).then((res) => {
	        
	        this.sele();
	        this.$message({
	          type: 'success',
	          message: '删除成功!'
	        });
	      })
	  
	    }).catch(() => {
	      this.$message({
	        type: 'info',
	        message: '已取消删除'
	      });
	    });
	  },
	  
  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>