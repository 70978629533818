<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
	.ProjectInfo1{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod2{
		width: 120px;
		/* float: right; */
	}
</style><!-- /* 著作信息 */ -->
<template>
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          <el-button style="height: 38px" type="primary" @click="dc">导出</el-button>
		  
		   <el-descriptions-item>
		        <template slot="label">
		          <i class="el-icon-user"></i>
		          用户名
		        </template>
		        kooriookami
		      </el-descriptions-item>
		  
		  
          <el-table :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;"  ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
			<el-table-column type="selection"  width="40px"></el-table-column>
			<el-table-column align="center" label="ID" 				prop="id" 			width="90"></el-table-column>
            <el-table-column align="center" label="编号" 			prop="bh" 			width="90"></el-table-column>
            <el-table-column align="center" label="姓名" 			prop="name" 		width="120px"> </el-table-column>
			<el-table-column align="center" label="论著名称" 		prop="treatiseName" 	width="100"></el-table-column>      
            <el-table-column align="center" label="论著出版类型" 	prop="cbtype"></el-table-column>       
			<el-table-column align="center" label="出版时间" 		prop="publishTime" 		width="150px"></el-table-column>      
			<el-table-column align="center" label="出版社" 			prop="publishingHouse"></el-table-column> 
			<el-table-column align="center" label="作者类型" 		prop="xwName"></el-table-column>
			<el-table-column align="center" label="论著类型" 		prop="treatiseCb" 		width="100px"></el-table-column>      
			<el-table-column align="center" label="主编" 			prop="eic" 		width="100px"></el-table-column>      
			<el-table-column align="center" label="字数(万)" 		prop="wordsNum"></el-table-column>       
            <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
				  <div>
					<el-button @click="handleupdateup(scope.row)" class="ProjectInfo" type="danger">项目信息</el-button>
					<el-button @click="handleupdate(scope.row.bh)" class="ProjectGrod" type="danger">项目组成员</el-button>   
				  </div>
				  <div style="margin-top: 10px;">
				  	<el-button @click="upmessage(scope.row)" class="ProjectInfo1" type="danger">编辑信息</el-button>
				  	<el-button @click="open(scope.row.id)" class="ProjectGrod2" type="danger">删除</el-button>
				  </div>
              </template>
            </el-table-column>
          </el-table>
		  
		  <div style="margin-top: 20px">
		  	<el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
		  	 <el-button @click="toggleSelection()">取消选择</el-button>
		  </div>
		<!--分页组件-->
		<el-pagination :current-page="currentPage" :page-size="pagesize" :page-sizes="[5, 10, 20, 50]"
		               :total="total" class="pagestrip" layout="total, sizes, prev, pager, next, jumper"
		               style="margin-top: 20px; float:right" @size-change="handleSizeChange" @current-change="handleCurrentChange">
		</el-pagination>
		  
		  
        </el-card>
      </div>
    </el-col>
	
	
	<el-dialog
	  title="项目信息"
	  :visible.sync="dialogFormVisiblegetup"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="编号:" :label-width="formLabelWidth">
				   <el-input v-model="form.bh" autocomplete="off" disabled></el-input>
				 </el-form-item>
	  			  <el-form-item label="姓名:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.name" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="论著名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.treatiseName" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="论著出版类型:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.cbtype" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="出版时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.publishTime" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="出版社:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.publishingHouse" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="作者类型:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.xwName" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="论著类型:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.treatiseCb" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="主编:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.eic" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="字数(万):" :label-width="formLabelWidth">
	  			    <el-input v-model="form.wordsNum" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="handleupqx()">取 消</el-button>
	  </span>
	</el-dialog>
	
	<el-dialog
	  title="编辑信息"
	  :visible.sync="upzz"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="编号:" :label-width="formLabelWidth">
				   <el-input v-model="form.bh" autocomplete="off" disabled></el-input>
				 </el-form-item>
	  			  <el-form-item label="姓名:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.name" autocomplete="off" ></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="论著名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.treatiseName" autocomplete="off" ></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="论著出版类型:" :label-width="formLabelWidth">
					<el-select v-model="form.isBz" clearable placeholder="请选择" style="width:200px;float: right">
					  <el-option v-for="item in cb" :key="item.id" :label="item.cbtype" :value="item.id"
					             clearable>
					  </el-option>
					  </el-option>
					</el-select>
	  			  </el-form-item>
	  			  <el-form-item label="出版时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.publishTime" autocomplete="off" ></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="出版社:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.publishingHouse" autocomplete="off" ></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="作者类型:" :label-width="formLabelWidth">
					<el-select v-model="form.xwName" clearable placeholder="请选择" style="width:200px;float: right">
					  <el-option v-for="item in zzxw" :key="item.id" :label="item.xwName" :value="item.id"
					             clearable>
					  </el-option>
					  </el-option>
					</el-select>
	  			  </el-form-item>
	  			  <el-form-item label="论著类型:" :label-width="formLabelWidth">
					<el-select v-model="form.treatiseCbtype" clearable placeholder="请选择" style="width:200px;float: right">
					  <el-option v-for="item in lztype" :key="item.id" :label="item.treatiseCb" :value="item.id"
					             clearable>
					  </el-option>
					  </el-option>
					</el-select>
	  			  </el-form-item>
	  			  <el-form-item label="主编:" :label-width="formLabelWidth">
					<el-select v-model="form.isZb" clearable placeholder="请选择" style="width:200px;float: right">
					  <el-option v-for="item in zb" :key="item.id" :label="item.eic" :value="item.id"
					             clearable>
					  </el-option>
					  </el-option>
					</el-select>
	  			  </el-form-item>
				  
	  			  <el-form-item label="字数(万):" :label-width="formLabelWidth">
	  			    <el-input v-model="form.wordsNum" autocomplete="off" ></el-input>
	  			  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="upqx()()">取 消</el-button>
	    <el-button type="primary" @click="upqd()">确 定</el-button>
	  </span>
	</el-dialog>
	
	
	
	
	
	
 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
		//分页
	currentPage:1,
	pagesize:5,
	total:0,
    formLabelWidth: "130px",//控制每一个名称加输入框的宽度
    tableData: [],
	multiplerTable:[],
	dialogFormVisiblegetup: false,//查看弹窗
	upzz:false,//编辑弹窗
	form: {},
	lztype:[],
	cb:[],
	zb:[],
	zzxw:[],
    };
  },
  created() {
    this.sele();
  },
  methods: {
	  dc(){
		 axios({
		        url: '/kjpaper/exportZzToExcel',
		        method: 'GET',
		        responseType: 'blob'
		      })
		      .then(response => {
		        const url = window.URL.createObjectURL(new Blob([response.data]));
		        const link = document.createElement('a');
		        link.href = url;
		        link.setAttribute('download', '著作信息.xlsx');
		        document.body.appendChild(link);
		        link.click();
		        document.body.removeChild(link);
		      })
		      .catch(error => {
		        // 处理错误
		      });
	  },
		  toggleSelection(rows) {
		         if (rows) {
		           rows.forEach(row => {
		             this.$refs.multipleTable.toggleRowSelection(row);
		           });
		         } else {
		           this.$refs.multipleTable.clearSelection();
		         }
		       },
		   handleSelectionChange(val) {
		     this.multipleSelection = val;
		   }, 
	//维表
	l(){
			 axios.get('/kjpaper/lztype').then((res) => {
			 	console.log(res.data)
			   this.lztype = res.data
			 }) 
	},
	c(){
			 axios.get('/kjpaper/lzcb').then((res) => {
			 	console.log(res.data)
			   this.cb = res.data
			 }) 
	},
	z(){
			 axios.get('/kjpaper/zb').then((res) => {
			 	console.log(res.data)
			   this.zb = res.data
			 }) 
	},
	x(){
			 axios.get('/kjpaper/zzxw').then((res) => {
			 	console.log(res.data)
			   this.zzxw = res.data
			 }) 
	},
	 //分页条
	 handleSizeChange(val) {
	   this.pagesize = val;
	   this.sele();
	 },
	 handleCurrentChange(val) {
	   this.currentPage = val;
	   this.sele();
	 }, 
	  
	  sele(){
		  axios.get('/kjpaper/getAllzz',{
			  params:{
				  currentPage: this.currentPage,
				  pageSize: this.pagesize,
			  }
		  }).then((res) => {
			  this.tableData = res.data.list
			  this.total = res.data.total
			  console.log(res.data.total)
			  console.log(res.data.list)
		  });
	  },
	 
	  //弹出查看窗口
	  handleupdateup(row){
	  	this.dialogFormVisiblegetup=true;
		var id=row.id
	  	axios.get('/kjpaper/getOnezz'+"?id="+id).then((res)=>{
	  		console.log(id);
	  		this.form=res.data;
	  		console.log(res.data);	
	  	})
	  	
	  },
	  handleupqx(){
	  	this.dialogFormVisiblegetup=false;
	  },
	  //弹出编辑窗口
	  upmessage(row){
		  this.l()
		  this.c()
		  this.x()
		  this.z()
		this.upzz=true;
		var id=row.id
		axios.get('/kjpaper/getOnezz'+"?id="+id).then((res)=>{
			console.log(id);
			this.form=res.data;
			console.log(res.data);	
		})
	  },
	  upqd(){
		  this.upzz=false
		axios.put('/kjpaper/upzz',this.form).then((res)=>{
					  console.log(res)
			if(res.data.code==0){
						console.log("修改成果")
						console.log(res.data)
				this.$message.success(res.data.message);
				this.sele();
			}else{
				this.$message.error(res.data.message);
			}
		})  
	  },
	  upqx(){
		this.upzz=false  
	  },
	  
	  
	  //是否删除
	  open(id) {
	    this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
	      confirmButtonText: '确定',
	      cancelButtonText: '取消',
	      type: 'warning'
	    }).then(() => {
	      axios.delete('/kjcg/workdeleteById?id='+id).then((res) => {
	        this.sele();
	        this.$message({
	          type: 'success',
	          message: '删除成功!'
	        });
	      })
	  
	    }).catch(() => {
	      this.$message({
	        type: 'info',
	        message: '已取消删除'
	      });
	    });
	  },
	  
	  
  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>