<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
	.ProjectInfo1{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod2{
		width: 120px;
		/* float: right; */
	}
</style><!-- /* 科技论文信息 */ -->
<template>
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          <el-button style="height: 38px" type="primary" @click="dc">导出</el-button>
		  
		   <el-descriptions-item>
		        <template slot="label">
		          <i class="el-icon-user"></i>
		          用户名
		        </template>
		        kooriookami
		      </el-descriptions-item>
		  
		  
          <el-table :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;"  ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
					<el-table-column type="selection"  width="40px"></el-table-column>
            <el-table-column align="center" label="ID" 			prop="id" 			width="90"></el-table-column>
			<el-table-column align="center" label="科室" 		prop="ks" 	width="100"></el-table-column>      
            <el-table-column align="center" label="姓名" 		prop="name" 		width="120px"> </el-table-column>
            <el-table-column align="center" label="论文题目" 	prop="tm"></el-table-column>       
			<el-table-column align="center" label="依托课题名称" prop="ktName" 		width="150px"></el-table-column>      
			<el-table-column align="center" label="期刊名称" 	prop="qkName"></el-table-column>      
			<el-table-column align="center" label="作者学位" 	prop="xwName" 		width="100px"></el-table-column>      
			<el-table-column align="center" label="作者类型" 	prop="zzType" 		width="100px"></el-table-column>      
			<el-table-column align="center" label="杂志名称" 	prop="zzName"></el-table-column>       
			<el-table-column align="center" label="论文或期刊水平说明" prop="sm1"></el-table-column>

            <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
				  <div>
					 <el-button @click="handleupdateup(scope.row)" class="ProjectInfo" type="danger">项目信息</el-button>
					 <el-button @click="handleupdateup(scope.row.bh)" class="ProjectGrod" type="danger">项目组成员</el-button>  
				  </div>
				  <div style="margin-top: 10px;">
				  	<el-button @click="upmessageb(scope.row)" class="ProjectInfo1" type="danger">编辑信息</el-button>
				  	<el-button @click="open(scope.row.id)" class="ProjectGrod2" type="danger">删除</el-button>
				  </div>
              </template>
            </el-table-column>
          </el-table>
		  
		  <div style="margin-top: 20px">
		  	<el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
		  	 <el-button @click="toggleSelection()">取消选择</el-button>
		  </div>
		<!--分页组件-->
		<el-pagination :current-page="currentPage" :page-size="pagesize" :page-sizes="[5, 10, 20, 50]"
		               :total="total" class="pagestrip" layout="total, sizes, prev, pager, next, jumper"
		               style="margin-top: 20px; float:right" @size-change="handleSizeChange" @current-change="handleCurrentChange">
		</el-pagination>
		  
		  
        </el-card>
      </div>
    </el-col>
	
	
	<el-dialog
	  title="项目信息"
	  :visible.sync="dialogFormVisiblegetup"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="科室:" :label-width="formLabelWidth">
				   <el-input v-model="form.ks" autocomplete="off" disabled></el-input>
				 </el-form-item>
	  			  <el-form-item label="姓名:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.name" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="论文题目:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.tm" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  <el-form-item label="论文类型:" :label-width="formLabelWidth">
				    <el-input v-model="form.lwType1" autocomplete="off" disabled></el-input>
				  </el-form-item>
				  <el-form-item label="依托课题编号:" :label-width="formLabelWidth">
				    <el-input v-model="form.ktBh" autocomplete="off" disabled></el-input>
				  </el-form-item>
	  			  <el-form-item label="依托课题名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ktName" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="期刊名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.qkName" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  <el-form-item label="年:" :label-width="formLabelWidth">
				    <el-input v-model="form.qkYear" autocomplete="off" disabled></el-input>
				  </el-form-item>
				  <el-form-item label="卷:" :label-width="formLabelWidth">
				    <el-input v-model="form.qkJ" autocomplete="off" disabled></el-input>
				  </el-form-item>
				  <el-form-item label="期:" :label-width="formLabelWidth">
				    <el-input v-model="form.qiQ" autocomplete="off"disabled></el-input>
				  </el-form-item>
				  <el-form-item label="起始页:" :label-width="formLabelWidth">
				    <el-input v-model="form.starterY" autocomplete="off" disabled></el-input>
				  </el-form-item>
				  <el-form-item label="终止页:" :label-width="formLabelWidth">
				    <el-input v-model="form.endY" autocomplete="off" disabled></el-input>
				  </el-form-item>
				  
	  			  <el-form-item label="作者学位:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.xwName" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="作者类型:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.zzType" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="杂志名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.zzName" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  <el-form-item label="年:" :label-width="formLabelWidth">
				    <el-input v-model="form.zzYear" autocomplete="off" disabled></el-input>
				  </el-form-item>
				  <el-form-item label="卷:" :label-width="formLabelWidth">
				    <el-input v-model="form.zzJ" autocomplete="off" disabled></el-input>
				  </el-form-item>
				  <el-form-item label="期:" :label-width="formLabelWidth">
				    <el-input v-model="form.zzQ" autocomplete="off" disabled></el-input>
				  </el-form-item>
				  <el-form-item label="发表年月:" :label-width="formLabelWidth">
				    <el-input v-model="form.fbTime" autocomplete="off" disabled></el-input>
				  </el-form-item>
	  			  <el-form-item label="论文或期刊水平说明:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.sm1" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  <el-form-item label="是否教改论文(0是1否):" :label-width="formLabelWidth">
				    <el-input v-model="form.isLw" autocomplete="off" disabled></el-input>
				  </el-form-item>
				  <el-form-item label="SCI影响因子:" :label-width="formLabelWidth">
				    <el-input v-model="form.sci" autocomplete="off" disabled></el-input>
				  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="handleupqx()">取 消</el-button> 
	  </span>
	</el-dialog>
	
	
	<!-- 编辑 -->
	<el-dialog
	  title="编辑信息"
	  :visible.sync="uptc"
	  width="55%"
	  center>
	  <el-form :inline=true :model="form1" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="科室:" :label-width="formLabelWidth">
				   <el-input v-model="form1.ks" autocomplete="off" ></el-input>
				 </el-form-item>
	  			  <el-form-item label="姓名:" :label-width="formLabelWidth">
	  			    <el-input v-model="form1.name" autocomplete="off" ></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="论文题目:" :label-width="formLabelWidth">
	  			    <el-input v-model="form1.tm" autocomplete="off" ></el-input>
	  			  </el-form-item>
				  <el-form-item label="论文类型:" :label-width="formLabelWidth">
					<el-select v-model="form1.lwType" clearable placeholder="请选择" style="width:250px;float: right" @change="lw()">
					  <el-option v-for="item in lwlx" :key="item.id" :label="item.lwType" :value="item.id"
					             clearable>
					  </el-option>
					</el-select>
				  </el-form-item>
				  <el-form-item label="依托课题编号:" :label-width="formLabelWidth">
				    <el-input v-model="form1.ktBh" autocomplete="off" ></el-input>
				  </el-form-item>
	  			  <el-form-item label="依托课题名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form1.ktName" autocomplete="off" ></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="期刊名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form1.qkName" autocomplete="off" ></el-input>
	  			  </el-form-item>
				  <el-form-item label="年:" :label-width="formLabelWidth">
				    <el-input v-model="form1.qkYear" autocomplete="off" ></el-input>
				  </el-form-item>
				  <el-form-item label="卷:" :label-width="formLabelWidth">
				    <el-input v-model="form1.qkJ" autocomplete="off" ></el-input>
				  </el-form-item>
				  <el-form-item label="期:" :label-width="formLabelWidth">
				    <el-input v-model="form1.qiQ" autocomplete="off"></el-input>
				  </el-form-item>
				  <el-form-item label="起始页:" :label-width="formLabelWidth">
				    <el-input v-model="form1.starterY" autocomplete="off" ></el-input>
				  </el-form-item>
				  <el-form-item label="终止页:" :label-width="formLabelWidth">
				    <el-input v-model="form1.endY" autocomplete="off" ></el-input>
				  </el-form-item>
				  
	  			  <el-form-item label="作者学位:" :label-width="formLabelWidth">
					<el-select v-model="form1.nameXw" clearable placeholder="请选择" style="width:250px;float: right" @change="xw()">
					  <el-option v-for="item in zzxw" :key="item.id" :label="item.xwName" :value="item.id"
					             clearable>
					  </el-option>
					</el-select>
	  			  </el-form-item>
	  			  <el-form-item label="作者类型:" :label-width="formLabelWidth">
					<el-select v-model="form1.nameType" clearable placeholder="请选择" style="width:250px;float: right" @change="lx()">
					  <el-option v-for="item in zzlx" :key="item.id" :label="item.zzType" :value="item.id"
					             clearable>
					  </el-option>
					</el-select>
	  			  </el-form-item>
	  			  <el-form-item label="杂志名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form1.zzName" autocomplete="off" ></el-input>
	  			  </el-form-item>
				  <el-form-item label="年:" :label-width="formLabelWidth">
				    <el-input v-model="form1.zzYear" autocomplete="off" ></el-input>
				  </el-form-item>
				  <el-form-item label="卷:" :label-width="formLabelWidth">
				    <el-input v-model="form1.zzJ" autocomplete="off" ></el-input>
				  </el-form-item>
				  <el-form-item label="期:" :label-width="formLabelWidth">
				    <el-input v-model="form1.zzQ" autocomplete="off" ></el-input>
				  </el-form-item>
				  <el-form-item label="发表年月:" :label-width="formLabelWidth">
				    <el-input v-model="form1.fbTime" autocomplete="off" ></el-input>
				  </el-form-item>
	  			  <el-form-item label="论文或期刊水平说明:" :label-width="formLabelWidth">
					<el-select v-model="form1.sm" clearable placeholder="请选择" style="width:250px;float: right" @change="sp()">
					  <el-option v-for="item in spsm" :key="item.id" :label="item.sm" :value="item.id"
					             clearable>
					  </el-option>
					</el-select>
	  			  </el-form-item>
				  <el-form-item label="是否教改论文(0是1否):" :label-width="formLabelWidth">
				    <el-input v-model="form1.isLw" autocomplete="off" ></el-input>
				  </el-form-item>
				  <el-form-item label="SCI影响因子:" :label-width="formLabelWidth">
				    <el-input v-model="form1.sci" autocomplete="off" ></el-input>
				  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="uptcqx()">取 消</el-button> 
		<el-button @click="uptcqd()">确 定</el-button> 
	  </span>
	</el-dialog>
	
	
	
	
	
	

 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
		//分页
	currentPage:1,
	pagesize:5,
	total:0,	
      formLabelWidth: "130px",//控制每一个名称加输入框的宽度
      tableData: [],
	  multiplerTable:[],
	  dialogFormVisiblegetup: false,//编辑弹窗是否可见false 不可见 true 可见
	  uptc:false,
	  form: {},
	  form1:{},
	  //维表
	  zzlx:[],
	  zzxw:[],
	  lwlx:[],
	  spsm:[],
    };
  },
  created() {
    this.sele();
  },
  methods: {
	  //导出
	  	  dc(){
	  		 axios({
	  		        url: '/kjpaper/kjexportZzToExcel',
	  		        method: 'GET',
	  		        responseType: 'blob'
	  		      })
	  		      .then(response => {
	  		        const url = window.URL.createObjectURL(new Blob([response.data]));
	  		        const link = document.createElement('a');
	  		        link.href = url;
	  		        link.setAttribute('download', '科技论文信息.xlsx');
	  		        document.body.appendChild(link);
	  		        link.click();
	  		        document.body.removeChild(link);
	  		      })
	  		      .catch(error => {
	  		        // 处理错误
	  		      });
	  	  },
		//页面多选框
	  toggleSelection(rows) {
	         if (rows) {
	           rows.forEach(row => {
	             this.$refs.multipleTable.toggleRowSelection(row);
	           });
	         } else {
	           this.$refs.multipleTable.clearSelection();
	         }
	       },
	   handleSelectionChange(val) {
	     this.multipleSelection = val;
	   },
	 /**
	  * @param {Object} val
	  * 维表查询
	  */
	 //作者类型
	 lx(){
		 axios.get('/kjpaper/zzlx').then((res) => {
		 	console.log(res.data)
		   this.zzlx = res.data
		 })
	 },
	 //作者学位
	 xw(){
		axios.get('/kjpaper/zzxw').then((res) => {
			console.log(res.data)
		  this.zzxw = res.data
		}) 
	 },
	 //论文类型
	 lw(){
		 axios.get('/kjpaper/lwtype').then((res) => {
		 	console.log(res.data)
		   this.lwlx = res.data
		 })
	 },
	 //水平说明
	 sp(){
		 axios.get('/kjpaper/sm').then((res) => {
		 	console.log(res.data)
		   this.spsm = res.data
		 })
	 },
	 //分页条
	 handleSizeChange(val) {
	   this.pagesize = val;
	   this.sele();
	 },
	 handleCurrentChange(val) {
	   this.currentPage = val;
	   this.sele();
	 }, 
	  
	  sele(){
		  axios.get('/kjpaper/getAllkj',{
			  params:{
				  currentPage: this.currentPage,
				  pageSize: this.pagesize,
			  }
		  }).then((res) => {
			  this.tableData = res.data.list
			  this.total = res.data.total
			  console.log(res.data.total)
			  console.log(res.data.list)
		  });
	  },
	  //弹出窗口
	  handleupdateup(row){
	  	this.dialogFormVisiblegetup=true;
		var id=row.id;
	  	axios.get('/kjpaper/getOne'+"?id="+id).then((res)=>{
	  		console.log(id);
	  		this.form=res.data;
	  		console.log(res.data);	
	  	})
	  	
	  },
	  //取消窗口
	  handleupqx(){
	  	this.dialogFormVisiblegetup=false;
	  },
	  //弹出编辑窗口
	  upmessageb(row){
		  this.lx()
		  this.xw()
		  this.lw()
		  this.sp()
		  this.uptc=true;
		  var id=row.id;
		  axios.get('/kjpaper/getOne'+"?id="+id).then((res)=>{
		  	console.log(id);
		  	this.form1=res.data;
			console.log("1234567890-")
			console.log(this.form1)
		  	console.log(res.data);	
		  })
	  },
	  //取消编辑弹窗
	  uptcqx(){
		  this.uptc=false
	  },
	  //确定编辑窗口
	  uptcqd(){
		  this.uptc=false
		  axios.put('/kjpaper/upkjlw',this.form1).then((res)=>{
		  			  console.log(res)
		  	if(res.data.code==0){
		  				console.log("修改成果")
		  				console.log(res.data)
		  		this.$message.success(res.data.message);
		  		this.sele();
		  	}else{
		  		this.$message.error(res.data.message);
		  	}
		  })
	  },
	  
	  //是否删除 
	  open(id) {
	    this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
	      confirmButtonText: '确定',
	      cancelButtonText: '取消',
	      type: 'warning'
	    }).then(() => {
	      axios.get('/kjpaper/delkjlw?id='+id).then((res) => {
	        this.sele();
	        this.$message({
	          type: 'success',
	          message: '删除成功!'
	        });
	      })
	  
	    }).catch(() => {
	      this.$message({
	        type: 'info',
	        message: '已取消删除'
	      });
	    });
	  },
	  
	  
	  
  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>