<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
	.ProjectInfo1{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod2{
		width: 120px;
		/* float: right; */
	}
</style>
<template>
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          <el-button style="height: 38px" type="primary" @click="dc">导出</el-button>
		  
		   <el-descriptions-item>
		        <template slot="label">
		          <i class="el-icon-user"></i>
		          用户名
		        </template>
		        kooriookami
		      </el-descriptions-item>
		  
		  
          <el-table  row-key="id" :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;"   ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
					   <el-table-column type="selection"  width="40px" :reserve-selection="true"></el-table-column>
            <el-table-column align="center" label="编号" prop="id" ></el-table-column>   
            <el-table-column align="center" label="项目编号" prop="bh" > </el-table-column>
			<el-table-column align="center" label="项目类别" prop="lbname" ></el-table-column>      
            <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
				<div>
					<el-button @click="handleupdateup(scope.row.id)" class="ProjectInfo" type="danger">项目信息</el-button>
					<el-button @click="handleupdate(scope.row.bh)" class="ProjectGrod" type="danger" disabled>项目组成员</el-button>
				</div>
				<div style="margin-top: 10px;">
					<el-button @click="upmessageb(scope.row)" class="ProjectInfo1" type="danger">编辑信息</el-button>
					<el-button @click="open(scope.row.id)" class="ProjectGrod2" type="danger">删除</el-button>
				</div>
              </template>
            </el-table-column>
          </el-table>
		 <div style="margin-top: 20px">
		     <el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
		     <el-button @click="toggleSelection()">取消选择</el-button>
		</div>
		  
		<!--分页组件-->
		<el-pagination :current-page="currentPage" :page-size="pagesize" :page-sizes="[5, 10, 20, 50]"
		               :total="total" class="pagestrip" layout="total, sizes, prev, pager, next, jumper"
		               style="margin-top: 20px; float:right" @size-change="handleSizeChange" @current-change="handleCurrentChange">
		</el-pagination>
		  
		  
        </el-card>
      </div>
    </el-col>
	
	
	<el-dialog
	  title="项目信息"
	  :visible.sync="dialogFormVisiblegetup"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 
	  			  <el-form-item label="项目编号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.bh" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="项目类别:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.lbname" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="handleupqx()">取 消</el-button>
	  </span>
	</el-dialog>
	
	
	<el-dialog
	  title="编辑信息"
	  :visible.sync="upmessage"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  <el-form :inline=true :model="formu" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 
	  			  <el-form-item label="项目编号:" :label-width="formLabelWidth">
	  			    <el-input v-model="formu.bh" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  <el-form-item label="项目类别:" :label-width="formLabelWidth">
				  		<el-select v-model="formu.xmlb" clearable placeholder="请选择" style="width:250px;float: right">
				  		  <el-option v-for="item in lbmmx" :key="item.id" :label="item.lbname" :value="item.id"
				  		             clearable>
				  		  </el-option>
				  		</el-select>
				  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="upbuttonqx()">取 消</el-button>
	    <el-button type="primary" @click="upbuttonqd()">确 定</el-button>
	  </span>
	</el-dialog>

	
 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
		//分页
	currentPage:1,
	pagesize:5,
	total:0,
	lbmmx:[],
	sextype:[
		{id:0,sexname:"女"},
		{id:1,sexname:"男"}
	],
      formLabelWidth: "130px",//控制每一个名称加输入框的宽度
      tableData: [],
	  multiplerTable:[],
	  FormData:[],
	  dialogFormVisiblegetup: false,//查看弹窗是否可见false 不可见 true 可见
	  dialogFormVisibleget:false,//查看项目成员
	  upmessage:false,//编辑项目信息弹窗
	  form: {},
	  formu:{}
    };
  },
  created() {
    this.sele();

  },
  methods: {

	  lbmm(){
	  		 axios.get('/wb/xmlb').then((res) => {
				 console.log("wertyui")
	  		 	console.log(res.data)
	  		   this.lbmmx = res.data
	  		 })  
	  },
	   toggleSelection(rows) {
	          if (rows) {
	            rows.forEach(row => {
	              this.$refs.multipleTable.toggleRowSelection(row);
	            });
	          } else {
	            this.$refs.multipleTable.clearSelection();
	          }
	        },
	    handleSelectionChange(val) {
	      this.multipleSelection = val;
		  console.log("weerty")
		  console.log(val.data)
	    },
	  
	 //分页条
	 handleSizeChange(val) {
	   this.pagesize = val;
	   this.sele();
	 },
	 handleCurrentChange(val) {
	   this.currentPage = val;
	   this.sele();
	 }, 
	sele(){
	  axios.get('/form/jtPage',{
		  params:{
			  currentPage: this.currentPage,
			  pageSize: this.pagesize,
		  }
	  }).then((res) => {
		  this.tableData = res.data.list
		  this.total = res.data.total
		  console.log(res.data.total)
		  console.log(res.data)
	  });
	},
	  //弹出查看窗口
	  handleupdateup(id){
	  	this.dialogFormVisiblegetup=true;
	  	axios.get('/form/getOnejt'+"?id="+id).then((res)=>{
	  		console.log(id);
			console.log("123456789")
			console.log(res)
	  		this.form=res.data;
	  		console.log(res.data);	
	  	})
	  	
	  },
	  //点击遍接按钮弹窗
	  upmessageb(row){
		  this.lbmm()
		  this.upmessage=true;
		  var id = row.id
		  axios.get('/form/getOnejt'+"?id="+id).then((res)=>{
		  	console.log(id);
		  	this.formu=res.data;
			console.log("根据id拆线呢出来的数据")
			console.log(res.data);	
		  	
		  })
	  },
	  upbuttonqx(){
		  this.upmessage=false;
	  },
	  upbuttonqd(){
		  this.upmessage=false;
		  axios.put('/form/upjt',this.formu).then((res)=>{
			  console.log(res)
		  	
		  		this.$message.success(res.data.message);
		  		this.sele();
		  	
		  })
		  
	  },
	  

	  //取消查看窗口
	  handleupqx(){
	  	this.dialogFormVisiblegetup=false;
	  },
	  
	  //取消查看项目组成员窗口
	  handle(){
	  	this.dialogFormVisibleget=false;
	  },
	  //弹出查看项目组成员窗口
	  handleupdate(bh){
		  console.log(bh)
	  	this.dialogFormVisibleget=true;
	  	var that=this;
	  	axios.get('/form/xmPeople'+"?bh="+bh).then((res)=>{
	  		console.log(bh);
	  		that.FormData=res.data;
			console.log("项目组成员")
	  		console.log(res.data);	
	  	})
	  	
	  },
	  
	  
	  dc(){
	  	  		 axios({
	  	  		        url: '/form/jtexportZzToExcel',
	  	  		        method: 'GET',
	  	  		        responseType: 'blob'
	  	  		      })
	  	  		      .then(response => {
	  	  		        const url = window.URL.createObjectURL(new Blob([response.data]));
	  	  		        const link = document.createElement('a');
	  	  		        link.href = url;
	  	  		        link.setAttribute('download', '项目结题.xlsx');
	  	  		        document.body.appendChild(link);
	  	  		        link.click();
	  	  		        document.body.removeChild(link);
	  	  		      })
	  	  		      .catch(error => {
	  	  		        // 处理错误
	  	  		      });
	  },
	  
	  
	  
	  //是否删除
	  	  open(id) {
	  	    this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
	  	      confirmButtonText: '确定',
	  	      cancelButtonText: '取消',
	  	      type: 'warning'
	  	    }).then(() => {
	  	      axios.delete('/form/deljt?id='+id).then((res) => {
	  	        this.sele();
	  	        this.$message({
	  	          type: 'success',
	  	          message: '删除成功!'
	  	        });
	  	      })
	  	  
	  	    }).catch(() => {
	  	      this.$message({
	  	        type: 'info',
	  	        message: '已取消删除'
	  	      });
	  	    });
	  	  },
	  
	
	 
  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>