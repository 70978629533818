import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//导入ElementUI的组件和样式
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

//导入axios
import axios from 'axios'
axios.defaults.baseURL = "/api/";
// axios.defaults.baseURL = "http://localhost:8088/";



//将以上四个对象统统挂载到Vue实例当中
Vue.prototype.$axios = axios;
Vue.use(ElementUI);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
