

<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
</style><!-- /* '精品课程信息 */ -->
<template>
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          <el-button style="height: 38px" type="primary" @click="dc">导出</el-button>
		  
		   <el-descriptions-item>
		        <template slot="label">
		          <i class="el-icon-user"></i>
		          用户名
		        </template>
		        kooriookami
		      </el-descriptions-item>
		  
		  
          <el-table :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;"  ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
			<el-table-column type="selection"  width="40px"></el-table-column>
			<el-table-column align="center" label="ID" 				prop="id" 		></el-table-column>
            <el-table-column align="center" label="研究对象姓名" 	prop="name" 	></el-table-column>
            <el-table-column align="center" label="性别" 			prop="sex" 		> </el-table-column>
			<el-table-column align="center" label="民族" 			prop="nation" 	></el-table-column>      
            <el-table-column align="center" label="国籍" 			prop="nationality"></el-table-column>       
			<el-table-column align="center" label="出生日期" 		prop="birthday" 		></el-table-column>      
			<el-table-column align="center" label="身份证号" 		prop="peopleId"></el-table-column>      
			<el-table-column align="center" label="手机号" 			prop="phone" 		></el-table-column>
			<el-table-column align="center" label="家属手机号" 		prop="familyPhone" 	></el-table-column>
			<el-table-column align="center" label="籍贯" 			prop="hometown" 	></el-table-column>
			<el-table-column align="center" label="血型" 			prop="blood" 		></el-table-column>
			<el-table-column align="center" label="身高" 			prop="height" 		></el-table-column>
            <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
				  <div>
                <el-button @click="handleupdateup(scope.row.id)" class="ProjectInfo" type="danger">项目信息</el-button>
				<el-button @click="handleupdate(scope.row)" class="ProjectGrod" type="danger">编辑信息</el-button> 
				</div>
				
				<div style="margin-top: 10px;">
					<el-button @click="handleupdateup(scope.row.id)" class="ProjectInfo" type="danger" disabled>项目信息</el-button>
					<el-button @click="open(scope.row.id)" class="ProjectGrod" type="danger">删除</el-button> 
				</div>
				
              </template>
            </el-table-column>
          </el-table>
		 <div style="margin-top: 20px">
		 	<el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
		 	 <el-button @click="toggleSelection()">取消选择</el-button>
		 </div> 
		<!--分页组件-->
		<el-pagination :current-page="currentPage" :page-size="pagesize" :page-sizes="[5, 10, 20, 50]"
		               :total="total" class="pagestrip" layout="total, sizes, prev, pager, next, jumper"
		               style="margin-top: 20px; float:right" @size-change="handleSizeChange" @current-change="handleCurrentChange">
		</el-pagination>
		  
		  
        </el-card>
      </div>
    </el-col>
	
	
	<el-dialog
	  title="项目信息"
	  :visible.sync="dialogFormVisiblegetup"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="研究对象姓名:" :label-width="formLabelWidth">
				   <el-input v-model="form.name" autocomplete="off"></el-input>
				 </el-form-item>
	  			  <el-form-item label="性别:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.sex" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="民族:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.nation" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="国籍:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.nationality" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="出生日期:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.birthday" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="身份证号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.peopleId" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="手机号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.phone" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="家属手机号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.familyPhone" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="籍贯:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.hometown" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="血型:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.blood" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="身高:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.height" autocomplete="off"></el-input>
	  			  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="handle()">取 消</el-button>
	  </span>
	</el-dialog>
	
	<!-- 编辑 -->
	<el-dialog
	  title="项目信息"
	  :visible.sync="upme"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="研究对象姓名:" :label-width="formLabelWidth">
				   <el-input v-model="form.name" autocomplete="off"></el-input>
				 </el-form-item>
	  			  <el-form-item label="性别:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.sex" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="民族:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.nation" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="国籍:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.nationality" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="出生日期:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.birthday" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="身份证号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.peopleId" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="手机号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.phone" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="家属手机号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.familyPhone" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="籍贯:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.hometown" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="血型:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.blood" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="身高:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.height" autocomplete="off"></el-input>
	  			  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="handleupqx()">取 消</el-button>
	    <el-button type="primary" @click="hanleupqd()">确 定</el-button>
	  </span>
	</el-dialog>
	
	
 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
		//分页
	currentPage:1,
	pagesize:5,
	total:0,
		
      formLabelWidth: "130px",//控制每一个名称加输入框的宽度
      tableData: [
	  ],
	  FormData:[],
	  multiplerTable:[],
	  dialogFormVisiblegetup: false,//编辑弹窗是否可见false 不可见 true 可见
	  upme:false,
	  radio: 0,
	    form: {
	       
	          },
    };
  },
  created() {
    this.sele();
  },
  methods: {
	  
	  
	  dc(){
	  	 axios({
	  	        url: '/zb/rkexportZzToExcel',
	  	        method: 'GET',
	  	        responseType: 'blob'
	  	      })
	  	      .then(response => {
	  	        const url = window.URL.createObjectURL(new Blob([response.data]));
	  	        const link = document.createElement('a');
	  	        link.href = url;
	  	        link.setAttribute('download', '人口.xlsx');
	  	        document.body.appendChild(link);
	  	        link.click();
	  	        document.body.removeChild(link);
	  	      })
	  	      .catch(error => {
	  	        // 处理错误
	  	      });
	    },
	  	  
	  
	  
	  
	 	  toggleSelection(rows) {
	 	         if (rows) {
	 	           rows.forEach(row => {
	 	             this.$refs.multipleTable.toggleRowSelection(row);
	 	           });
	 	         } else {
	 	           this.$refs.multipleTable.clearSelection();
	 	         }
	 	       },
	 	   handleSelectionChange(val) {
	 	     this.multipleSelection = val;
	 	   }, 
	 //分页条
	 handleSizeChange(val) {
	   this.pagesize = val;
	   this.sele();
	 },
	 handleCurrentChange(val) {
	   this.currentPage = val;
	   this.sele();
	 }, 
	  
	  sele(){
		  axios.get('/zb/getAllrk',{
			  params:{
				  currentPage: this.currentPage,
				  pageSize: this.pagesize,
			  }
		  }).then((res) => {
			  this.tableData = res.data.list
			  this.total = res.data.total
			  console.log(res.data.total)
			  console.log(res.data.list)
		  });
	  },
	  
	 
	  //弹出编辑窗口
	  handleupdateup(id){
	  	this.dialogFormVisiblegetup=true;
	  	axios.get('/zb/populationGetById'+"?id="+id).then((res)=>{
	  		console.log(id);
	  		this.form=res.data;
	  		console.log(res.data);	
	  	})
	  	
	  },
	  //取消查看窗口
	  handle(){
	  	this.dialogFormVisibleget=false;
	  },
	  
	  
	  //确定编辑
	  hanleupqd(){
	  	this.upme=false;
		axios.put('/zb/populationUpdateById',this.form).then((res)=>{
					  console.log(res)
			if(res.data.code==0){
						console.log("修改成果")
						console.log(res.data)
				this.$message.success(res.data.message);
				this.sele();
			}else{
				this.$message.error(res.data.message);
			}
		})
	  	
	  },
	  //取消编辑窗口
	  handleupqx(){
	  	this.upme=false;
	  },
	  
	
	  //弹出查看窗口
	  handleupdate(row){
		  
	  	this.upme=true;
	  	var id=row.id;
	  	axios.get('/zb/populationGetById'+"?id="+id).then((res)=>{
	  		console.log(id);
	  		this.form=res.data;
	  		console.log(res.data);	
	  	})
	  	
	  },
	  
	  
	  //是否删除
	  open(id) {
	    this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
	      confirmButtonText: '确定',
	      cancelButtonText: '取消',
	      type: 'warning'
	    }).then(() => {
	      axios.delete('/zb/populationDeleteById?id='+id).then((res) => {
	        this.sele();
	        this.$message({
	          type: 'success',
	          message: '删除成功!'
	        });
	      })
	  
	    }).catch(() => {
	      this.$message({
	        type: 'info',
	        message: '已取消删除'
	      });
	    });
	  },
	  
	  

  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>