
<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
</style>
<template>
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          <el-button style="height: 38px" type="primary" @click="dc">导出</el-button>
		  
		   <el-descriptions-item>
		        <template slot="label">
		          <i class="el-icon-user"></i>
		          用户名
		        </template>
		        kooriookami
		      </el-descriptions-item>
		  
		  
          <el-table :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;"  ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
					<el-table-column type="selection"  width="40px"></el-table-column>
            <el-table-column align="center" label="编号" 			prop="id" 			></el-table-column>
			
            <el-table-column align="center" label="科室" 			prop="ks" 		> </el-table-column>
			<el-table-column align="center" label="姓名" 			prop="name" 	></el-table-column>      
            <el-table-column align="center" label="学术交流项目" 	prop="scienceXm"></el-table-column>       
			<el-table-column align="center" label="学术交流类型" 	prop="commuicationName" 		></el-table-column>      
			<el-table-column align="center" label="交流时间" 		prop="communicationTime"></el-table-column>      
			<el-table-column align="center" label="地点" 			prop="address" 		></el-table-column>      
			<el-table-column align="center" label="报告人" 			prop="lecturer" 		></el-table-column>      
			<el-table-column align="center" label="主办单位" 		prop="sponsor"></el-table-column>   
			<el-table-column align="center" label="交流对象" 		prop="communicationObject" 		></el-table-column>
			<el-table-column align="center" label="参加人数" 		prop="joinNum" 		></el-table-column>      

            <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
				  <div>
					<el-button @click="handleupdateup(scope.row.id)" class="ProjectInfo" type="danger">项目信息</el-button>
					<el-button @click="handleupdate(scope.row.bh)" class="ProjectGrod" type="danger" disabled>项目组成员</el-button>   
				  </div>
				  <div style="margin-top: 10px;">
					  <el-button @click="upjlme(scope.row)" class="ProjectInfo" type="danger">编辑信息</el-button>
					  <el-button @click="open(scope.row.id)" class="ProjectGrod" type="danger">删除</el-button> 
				  </div>

				
              </template>
            </el-table-column>
          </el-table>
		  <div style="margin-top: 20px">
		  	<el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
		  	 <el-button @click="toggleSelection()">取消选择</el-button>
		  </div>
		<!--分页组件-->
		<el-pagination :current-page="currentPage" :page-size="pagesize" :page-sizes="[5, 10, 20, 50]"
		               :total="total" class="pagestrip" layout="total, sizes, prev, pager, next, jumper"
		               style="margin-top: 20px; float:right" @size-change="handleSizeChange" @current-change="handleCurrentChange">
		</el-pagination>
		  
		  
        </el-card>
      </div>
    </el-col>
	
	
	<el-dialog
	  title="项目信息"
	  :visible.sync="dialogFormVisiblegetup"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="科室:" :label-width="formLabelWidth">
				   <el-input v-model="form.ks" autocomplete="off"></el-input>
				 </el-form-item>
	  			  <el-form-item label="姓名:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.name" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="学术交流项目:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.scienceXm" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="学术交流类型:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.commuicationName" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="交流时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.communicationTime" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="地点:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.address" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="报告人:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.lecturer" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="主办单位:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.sponsor" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="交流对象:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.communicationObject" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="参加人数:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.joinNum" autocomplete="off"></el-input>
	  			  </el-form-item>
	   </el-form>
	   
	   <span slot="footer" class="dialog-footer">
	       <el-button @click="handleupqx()">取 消</el-button>
	     </span>
	   </el-dialog>
	   
	   <el-dialog
	     title="编辑信息"
	     :visible.sync="upjl"
	     width="55%"
	     center>
	     <!-- width : 对话框的大小-->
	     <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
	   			 <el-form-item label="科室:" :label-width="formLabelWidth">
	   			   <el-input v-model="form.ks" autocomplete="off"></el-input>
	   			 </el-form-item>
	     			  <el-form-item label="姓名:" :label-width="formLabelWidth">
	     			    <el-input v-model="form.name" autocomplete="off"></el-input>
	     			  </el-form-item>
	     			  <el-form-item label="学术交流项目:" :label-width="formLabelWidth">
	     			    <el-input v-model="form.scienceXm" autocomplete="off"></el-input>
	     			  </el-form-item>
	     			  <el-form-item label="学术交流类型:" :label-width="formLabelWidth">
						<el-select v-model="form.scienceType" clearable placeholder="请选择" style="width:200px;float: right">
						  <el-option v-for="item in jlty" :key="item.id" :label="item.jlname" :value="item.id"
						             clearable>
						  </el-option>
						  </el-option>
						</el-select>
	     			  </el-form-item>
	     			  <el-form-item label="交流时间:" :label-width="formLabelWidth">
	     			    <el-input v-model="form.communicationTime" autocomplete="off"></el-input>
	     			  </el-form-item>
	   			  
	     			  <el-form-item label="地点:" :label-width="formLabelWidth">
	     			    <el-input v-model="form.address" autocomplete="off"></el-input>
	     			  </el-form-item>
	     			  <el-form-item label="报告人:" :label-width="formLabelWidth">
	     			    <el-input v-model="form.lecturer" autocomplete="off"></el-input>
	     			  </el-form-item>
	     			  <el-form-item label="主办单位:" :label-width="formLabelWidth">
	     			    <el-input v-model="form.sponsor" autocomplete="off"></el-input>
	     			  </el-form-item>
	     			  <el-form-item label="交流对象:" :label-width="formLabelWidth">
	     			    <el-input v-model="form.communicationObject" autocomplete="off"></el-input>
	     			  </el-form-item>
	   			  
	     			  <el-form-item label="参加人数:" :label-width="formLabelWidth">
	     			    <el-input v-model="form.joinNum" autocomplete="off"></el-input>
	     			  </el-form-item>
	      </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="upjlqx()">取 消</el-button>
	    <el-button type="primary" @click="upjlqd()">确 定</el-button>
	  </span>
	</el-dialog>

 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
		//分页
	currentPage:1,
	pagesize:5,
	total:0,
      formLabelWidth: "130px",//控制每一个名称加输入框的宽度
      tableData: [],
	  FormData:[],
	  multiplerTable:[],
	  dialogFormVisiblegetup: false,//编辑弹窗是否可见false 不可见 true 可见
	  upjl:false,
	  jlty:[
	  	{id:1,jlname:"科研"},
	  	{id:2,jlname:"教学"},
		{id:3,jlname:"其他"}
	  ],
	    form: {},
    };
  },
  created() {
    this.sele();
  },
  methods: {
	  //导出
	  	  dc(){
	  		 axios({
	  		        url: '/kjpaper/jlexportZzToExcel',
	  		        method: 'GET',
	  		        responseType: 'blob'
	  		      })
	  		      .then(response => {
	  		        const url = window.URL.createObjectURL(new Blob([response.data]));
	  		        const link = document.createElement('a');
	  		        link.href = url;
	  		        link.setAttribute('download', '学术交流.xlsx');
	  		        document.body.appendChild(link);
	  		        link.click();
	  		        document.body.removeChild(link);
	  		      })
	  		      .catch(error => {
	  		        // 处理错误
	  		      });
	  	  },

	  //页面多选
	    toggleSelection(rows) {
	  	         if (rows) {
	  	           rows.forEach(row => {
	  	             this.$refs.multipleTable.toggleRowSelection(row);
	  	           });
	  	         } else {
	  	           this.$refs.multipleTable.clearSelection();
	  	         }
	  	       },
	  	   handleSelectionChange(val) {
	  	     this.multipleSelection = val;
	  	   },

	 //分页条
	 handleSizeChange(val) {
	   this.pagesize = val;
	   this.sele();
	 },
	 handleCurrentChange(val) {
	   this.currentPage = val;
	   this.sele();
	 }, 
	  
	  sele(){
		  axios.get('/kjpaper/getAlljl',{
			  params:{
				  currentPage: this.currentPage,
				  pageSize: this.pagesize,
			  }
		  }).then((res) => {
			  this.tableData = res.data.list
			  this.total = res.data.total
			  console.log(res.data.total)
			  console.log(res.data.list)
		  });
	  },
	
	 
	  //弹出查看窗口
	  handleupdateup(id){
	  	this.dialogFormVisiblegetup=true;
	  	axios.get('/kjpaper/getOnejl'+"?id="+id).then((res)=>{
	  		console.log(id);
	  		this.form=res.data;
	  		console.log(res.data);	
	  	})
	  	
	  },
	  //取消查看窗口
	  handleupqx(){
	  	this.dialogFormVisiblegetup=false;
	  },
	   //确定编辑
	   upjlqd(){
		   this.upjl=false
		   axios.put('/kjcg/scienceupdateById',this.form).then((res)=>{
		   			  console.log(res)
		   	if(res.data.code==0){
		   				console.log("修改成果")
		   				console.log(res.data)
		   		this.$message.success(res.data.message);
		   		this.sele();
		   	}else{
		   		this.$message.error(res.data.message);
		   	}
		   })
	   },
	  //取消编辑窗口
	  upjlqx(){
	  	this.upjl=false;
	  },
	  //弹出编辑窗口
	  upjlme(row){
	  	this.upjl=true;
		var id= row.id
		axios.get('/kjpaper/getOnejl'+"?id="+id).then((res)=>{
			console.log(id);
			this.form=res.data;
			console.log(res.data);	
		})
	  	
	  },
	  
	  
	  
	  //是否删除
	    open(id) {
	      this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
	        confirmButtonText: '确定',
	        cancelButtonText: '取消',
	        type: 'warning'
	      }).then(() => {
	        axios.delete('/kjcg/sciencedeleteById?id='+id).then((res) => {
	          this.sele();
	          this.$message({
	            type: 'success',
	            message: '删除成功!'
	          });
	        })
	    
	      }).catch(() => {
	        this.$message({
	          type: 'info',
	          message: '已取消删除'
	        });
	      });
	    },
	  
	
  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>