<template>
	<div>
		 <el-card>
		        <el-tabs  class="tabRight"  v-model="activeName"  type="card"  @tab-click="handleClick">
		       <el-tab-pane 	label="待审核" 		name="one" 		:key="one"></el-tab-pane>
		        <el-tab-pane 	label="审核通过" 	name="two" 		:key="two"></el-tab-pane>
				<el-tab-pane 	label="未通过" 		name="three" 	:key="three"></el-tab-pane>
		        </el-tabs>
		       <child1 v-if="activeName	==	'one'" 	/>
		       <child2 v-if="activeName	==	'two'" 	/>
			   <child3 v-if="activeName	==	'three'" />
		</el-card>
	</div>
</template>

<script>
	import enviroChild1 from './enviroChild1';
	import enviroChild2 from './enviroChild2';
	import enviroChild3 from './enviroChild13'
  export default {
	    name: "",
	    components: {
	       child1: enviroChild1,
	       child2: enviroChild2,
		   child3: enviroChild3
	    },
    data() {
      return {
        activeName: 'one'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  };
</script>

<style>
</style>