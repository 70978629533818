<template>
	<div>
		 <el-card>
		        <el-tabs  class="tabRight"  v-model="activeName"  type="card"  @tab-click="handleClick">
		       <el-tab-pane 	label="全部" 		name="one" 		:key="one"></el-tab-pane>
		        <el-tab-pane 	label="待审核" 	name="two" 		:key="two"></el-tab-pane>
				
		        </el-tabs>
		       <child1 v-if="activeName	==	'one'" 	/>
		       <child2 v-if="activeName	==	'two'" 	/>
			   
		</el-card>
	</div>
</template>

<script>
	import shuser1 from './shuser1.vue';
	import shuser2 from './shuser2.vue';
	
  export default {
	    name: "",
	    components: {
	       child1: shuser1,
	       child2: shuser2
		   
	    },
    data() {
      return {
        activeName: 'one'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  };
</script>

<style>
</style>