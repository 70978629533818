<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
</style>
<template>
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          <!-- <el-button style="height: 38px" type="primary"
                     >导出
          </el-button> -->
		  </el-descriptions-item>
          <el-table :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;">
            <el-table-column align="center" label="用户id" prop="id" ></el-table-column>
			<el-table-column align="center" label="用户名" prop="username" ></el-table-column>      
            <el-table-column align="center" label="科室" prop="companyName" > </el-table-column>
			<el-table-column align="center" label="手机号" prop="phone" > </el-table-column>
            <el-table-column align="center" label="用户注册时间" prop="createTime"></el-table-column>       
            <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
                <el-button @click="handleupdateup(scope.row.id)"   class="ProjectInfo" type="danger">修改密码</el-button>
				<el-button @click="handleupdate(scope.row.id)" class="ProjectGrod" type="danger" >查看信息</el-button> 
              </template>
            </el-table-column>
          </el-table> 
		  
		  
		  
		  <!-- 修改密码 dialog -->
		  <el-dialog :before-close="closeEditPassword" :close-on-click-modal="false" :visible.sync="editPasswordDialog" title="修改密码"
		             width="30%">
		    <el-form ref="editPasswordForm" :model="editPasswordForm" :rules="editPasswordRule"
		             class="demo-form-inline" label-width="90px">
		  	<el-form-item label="手机号:" prop="oldname">
		  	  <el-input v-model="editPasswordForm.oldname" placeholder="请输入"/>
		  	</el-form-item>
		      <el-form-item label="新密码:" prop="newPassword">
		        <el-input type="password" v-model="editPasswordForm.newPassword" placeholder="请输入新密码"/>
		      </el-form-item>
		      <el-form-item label="确认密码:" prop="confirmPassword">
		        <el-input type="password" v-model="editPasswordForm.confirmPassword" placeholder="请确认密码"/>
		      </el-form-item>
		      <el-form-item>
		        <el-button size="small" @click="closeEditPassword()">取 消</el-button>
		        <el-button size="small" type="primary" @click="editPassword()">保 存</el-button>
		      </el-form-item>
		    </el-form>
		  </el-dialog>
		  
		  
		  
		  <!-- 修改个人信息-->
		  <el-dialog
		    title="用户信息"
		    :visible.sync="upme"
		    width="55%"
		    center>
		    <!-- width : 对话框的大小-->
		    <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
		  			
		    			  <el-form-item label="用户名:" :label-width="formLabelWidth">
		    			    <el-input v-model="form.username" autocomplete="off"></el-input>
		    			  </el-form-item>
		    			  <el-form-item label="公司名称:" :label-width="formLabelWidth">
		    			    <el-input v-model="form.companyName" autocomplete="off"></el-input>
		    			  </el-form-item>
						  <el-form-item label="用户头像:" :label-width="formLabelWidth">
						    <!-- <el-input v-model="form.image" autocomplete="off"></el-input> -->
							<template slot-scope="scope">
								<img  :src="form.image" min-width="100" height="100"/>
							</template>
						  </el-form-item>
						  <el-form-item label="手机号:" :label-width="formLabelWidth">
						    <el-input v-model="form.phone" autocomplete="off"></el-input>
						  </el-form-item>
		     </el-form>
		    <span slot="footer" class="dialog-footer">
		      <el-button @click="upbuttonqx()">取 消</el-button>
		      <el-button type="primary" @click="upbuttonqd()">确 定</el-button>
		    </span>
		  </el-dialog>
		  
		  
		  
		  
		  
        </el-card>
      </div>
    </el-col>
 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
		upme:false,
		editPasswordDialog: false,
		form:{
			image:"",
			username:"",
			companyName:"",
			phone:""
			
		},
				editPasswordForm: {
					
					newPassword: "",
					confirmPassword: "",
					oldname:"",
					
				},
				editPasswordRule: {
					oldname:[{
						required: true,
						message:"请输入用户名",
						trigger:"blur"
					},],
				
					newPassword: [{
						required: true,
						message: "请输入新密码",
						trigger: "blur"
					}, ],
					confirmPassword: [{
						required: true,
						message: "请确认新密码",
						trigger: "blur"
					}, ],
				},
      formLabelWidth: "12",
      tableData: []

    };
  },

  created() {
    this.sele();
	
  },
  methods: {
	  
	  
	  
	  
	  // 修改密码
	  editPassword() {
		  this.editPasswordDialog=true
	  	axios.get('/userlogin/uppwd',{
	  		params:{
	  			password :this.editPasswordForm.newPassword,
	  			phone :this.editPasswordForm.oldname,
	  			
	  		}
	  	}).then((res)=>{
	  		if(this.editPasswordForm.newPassword==this.editPasswordForm.confirmPassword){
	  			console.log(res)
	  			this.$message.success(res.data.message);
	  			sessionStorage.clear();
	  			// this.$router.push("/")
	  		}else{
	  			this.$message.error("两次密码不一致");
	  		}
	  	})
	  },
	  // 取消关闭密码
	  closeEditPassword() {
	  	this.editPasswordDialog = false;
	  	// 坑：resetFields 方法只能重置带有 props 属性的元素
	  	this.$refs.editPasswordForm.resetFields();
	  },
	  	
	  
	  
	  
	  
	  
	  //点击修改出现查询弹窗
	   handleupdateup(id){
	  	  this.editPasswordDialog=true
	  	  axios.get('/userlogin/getOne'+"?id="+id).then((res)=>{
	  	  	console.log("234567")
			console.log(id);
	  	  	console.log(res.data);	
			 
	  	  })
	    },
	    

	
	  sele(){
		  axios.get('/userlogin/getAll',{
		  }).then((res) => {
		    this.tableData = res.data
			
			console.log("0000000")
			
		    console.log(this.tableData)
		  });
	  },
	  
	  
	  
	  //小程序用户信息修改
	  handleupdate(id){
		  this.upme=true
		  axios.get('/userlogin/getOne'+"?id="+id).then((res)=>{
		  	this.form=res.data
			console.log("234567")
		  	console.log(id);
			
		  	console.log(res.data);	
			// 假设 res.data.image 包含图片的相对路径或不完整的 URL
			   // 例如： "path/to/image.jpg" 或 "www.example.com/path/to/image.jpg"
			   const imageUrl = res.data.image;
						
			   // 将前缀 https:// 添加到图片 URL 前面
			   // 如果 res.data.image 已经是完整的 URL，以下代码不会重复添加 https:// 前缀
			   const fullImageUrl = imageUrl.startsWith('http') ? imageUrl : 'https://research.ruanjian.art/uploads/' + imageUrl;
							console.log("typabb")
							console.log(fullImageUrl)
			   // 将完整的图片 URL 赋值给 form.image
			   this.form.image = fullImageUrl;
		  })
	  },
	  upbuttonqd(){
		  this.upme=false
	  },
	  upbuttonqx(){
		  this.upme=false
	  },
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>