import { render, staticRenderFns } from "./shuser2.vue?vue&type=template&id=6b437b8d&scoped=true&"
import script from "./shuser2.vue?vue&type=script&lang=js&"
export * from "./shuser2.vue?vue&type=script&lang=js&"
import style0 from "./shuser2.vue?vue&type=style&index=0&id=6b437b8d&prod&lang=css&"
import style1 from "./shuser2.vue?vue&type=style&index=1&id=6b437b8d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b437b8d",
  null
  
)

export default component.exports