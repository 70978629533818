
<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
</style><!-- /* '精品课程信息 */ -->
<template>
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          <el-button style="height: 38px" type="primary" @click="dc">导出</el-button>
		  
		   <el-descriptions-item>
		        <template slot="label">
		          <i class="el-icon-user"></i>
		          用户名
		        </template>
		        kooriookami
		      </el-descriptions-item>
		  
		  
          <el-table :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;"  ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
					<el-table-column type="selection"  width="40px"></el-table-column>
            <el-table-column align="center" label="编号" 			prop="id" 			></el-table-column>
			
            <el-table-column align="center" label="科室" 			prop="ks" 		> </el-table-column>
			<el-table-column align="center" label="中心负责人" 		prop="centerResponsiblePerson" 	></el-table-column>      
            <el-table-column align="center" label="临床医学研究中心名称" 		prop="centerName"></el-table-column>       
			<el-table-column align="center" label="依托单位" 		prop="trustUnit" 		></el-table-column>      
			<el-table-column align="center" label="中心获批类型" 	prop="type"></el-table-column>      
			<el-table-column align="center" label="获批时间" 		prop="approvalTime" 		></el-table-column>      
			<el-table-column align="center" label="获批经费" 		prop="approvedFunds" 		></el-table-column>      
			<el-table-column align="center" label="配套经费" 		prop="matchingFunds" 		></el-table-column>
            <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
				  <div>
					<el-button @click="handleupdateup(scope.row.id)" class="ProjectInfo" type="danger">项目信息</el-button>
					<el-button @click="handleupdate(scope.row)" class="ProjectGrod" type="danger">项目组成员</el-button>   
				  </div>
				  
				  <div style="margin-top: 10px;">
				  	<el-button @click="handleupdateup(scope.row.id)" class="ProjectInfo" type="danger" disabled>项目信息</el-button>
				  	<el-button @click="open(scope.row.id)" class="ProjectGrod" type="danger">删除</el-button>   
				  </div>

				
              </template>
            </el-table-column>
          </el-table>
		  <div style="margin-top: 20px">
		  	<el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
		  	 <el-button @click="toggleSelection()">取消选择</el-button>
		  </div>
		<!--分页组件-->
		<el-pagination :current-page="currentPage" :page-size="pagesize" :page-sizes="[5, 10, 20, 50]"
		               :total="total" class="pagestrip" layout="total, sizes, prev, pager, next, jumper"
		               style="margin-top: 20px; float:right" @size-change="handleSizeChange" @current-change="handleCurrentChange">
		</el-pagination>
		  
		  
        </el-card>
      </div>
    </el-col>
	
	
	<el-dialog
	  title="项目信息"
	  :visible.sync="dialogFormVisiblegetup"
	  width="55%"
	  center>
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="编号:" :label-width="formLabelWidth">
				   <el-input v-model="form.id" autocomplete="off" disabled></el-input>
				 </el-form-item>
	  			  <el-form-item label="科室:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ks" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="中心负责人:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.centerResponsiblePerson" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="临床医学研究中心名:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.centerName" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="依托单位:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.trustUnit" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="中心获批类型:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.type" autocomplete="off" disabled></el-input>
	  			  </el-form-item> 
				  <el-form-item label="获批时间:" :label-width="formLabelWidth">
				    <el-input v-model="form.approvalTime" autocomplete="off" disabled></el-input>
				  </el-form-item>
	  			  <el-form-item label="获批经费:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.approvedFunds" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="配套经费:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.matchingFunds" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="handleupqx()">取 消</el-button>
	  </span>
	</el-dialog>
	
	<!-- 编辑 -->
	<el-dialog
	  title="项目信息"
	  :visible.sync="uph"
	  width="55%"
	  center>
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="编号:" :label-width="formLabelWidth">
				   <el-input v-model="form.id" autocomplete="off" ></el-input>
				 </el-form-item>
	  			  <el-form-item label="科室:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ks" autocomplete="off" ></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="中心负责人:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.centerResponsiblePerson" autocomplete="off" ></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="临床医学研究中心名:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.centerName" autocomplete="off" ></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="依托单位:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.trustUnit" autocomplete="off" ></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="中心获批类型:" :label-width="formLabelWidth">
	  			    
					<el-select v-model="form.approvalType" clearable placeholder="请选择" style="width:200px;float: right">
					  <el-option v-for="item in lxt" :key="item.id" :label="item.type" :value="item.id"
					             clearable>
					  </el-option>
					  </el-option>
					</el-select>
	  			  </el-form-item> 
				  <el-form-item label="获批时间:" :label-width="formLabelWidth">
				    <el-input v-model="form.approvalTime" autocomplete="off" ></el-input>
				  </el-form-item>
	  			  <el-form-item label="获批经费:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.approvedFunds" autocomplete="off" ></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="配套经费:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.matchingFunds" autocomplete="off" ></el-input>
	  			  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="upqx()">取 消</el-button>
	    <el-button type="primary" @click="upqd()">确 定</el-button>
	  </span>
	</el-dialog>
	
	
	
	
	
	
	
	
 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
		//分页
	currentPage:1,
	pagesize:5,
	total:0,
		
      formLabelWidth: "130px",//控制每一个名称加输入框的宽度
      tableData: [
	  ],
	  FormData:[],
	  multiplerTable:[],
	  dialogFormVisiblegetup: false,//编辑弹窗是否可见false 不可见 true 可见
	  uph:false,
	  lxt: [],
	    form: {},
    };
  },
  created() {
    this.sele();
  },
  methods: {
	  //导出
	  	  dc(){
	  		 axios({
	  		        url: '/kypt/lcexportZzToExcel',
	  		        method: 'GET',
	  		        responseType: 'blob'
	  		      })
	  		      .then(response => {
	  		        const url = window.URL.createObjectURL(new Blob([response.data]));
	  		        const link = document.createElement('a');
	  		        link.href = url;
	  		        link.setAttribute('download', '临床信息.xlsx');
	  		        document.body.appendChild(link);
	  		        link.click();
	  		        document.body.removeChild(link);
	  		      })
	  		      .catch(error => {
	  		        // 处理错误
	  		      });
	  	  },
	  //维表查询
	  lx(){
	  		 axios.get('/kypt/lch').then((res)=>{
	  		 	
	  		 	this.lxt=res.data;
	  		 	console.log(res.data);	
	  		 }) 
	  },
		  toggleSelection(rows) {
		         if (rows) {
		           rows.forEach(row => {
		             this.$refs.multipleTable.toggleRowSelection(row);
		           });
		         } else {
		           this.$refs.multipleTable.clearSelection();
		         }
		       },
		   handleSelectionChange(val) {
		     this.multipleSelection = val;
		   },  
	 //分页条
	 handleSizeChange(val) {
	   this.pagesize = val;
	   this.sele();
	 },
	 handleCurrentChange(val) {
	   this.currentPage = val;
	   this.sele();
	 }, 
	  
	  sele(){
		  axios.get('/kypt/lcPage',{
			  params:{
				  currentPage: this.currentPage,
				  pageSize: this.pagesize,
			  }
		  }).then((res) => {
			  this.tableData = res.data.list
			  this.total = res.data.total
			  console.log(res.data.total)
			  console.log(res.data.list)
		  });
	  },
	 
	  //弹出查看窗口
	  handleupdateup(id){
	  	this.dialogFormVisiblegetup=true;
	  	axios.get('/kypt/getOnel'+"?id="+id).then((res)=>{
	  		console.log(id);
	  		this.form=res.data;
	  		console.log(res.data);	
	  	})
	  	
	  },
	  //取消查看窗口
	  handleupqx(){
	  	this.dialogFormVisiblegetup=false;
	  },
	
	  //取消编辑窗口
	  upqx(){
	  	this.uph=false;
	  },
	  //确定编辑
	  upqd(){
	  	this.uph=false;
		axios.put('/kypt/updateClinical',this.form).then((res)=>{
					  console.log(res)
			if(res.data==''){
						console.log("修改成果")
						console.log(res.data)
				this.$message.success(res.data.message);
				this.sele();
			}else{
				this.$message.error(res.data.message);
			}
		})
	  },
	  //弹出编辑窗口
	  handleupdate(row){
		  this.uph=true
		  this.lx()
		  var id=row.id
		  
		axios.get('/kypt/getOnel'+"?id="+id).then((res)=>{
			console.log(id);
			this.form=res.data;
			console.log(res.data);	
		})
	  },
	  
	   //是否删除
	  	  open(id) {
	  	    this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
	  	      confirmButtonText: '确定',
	  	      cancelButtonText: '取消',
	  	      type: 'warning'
	  	    }).then(() => {
	  	      axios.delete('/kypt/deleteClinical?id='+id).then((res) => {
	  	        this.sele();
	  	        this.$message({
	  	          type: 'success',
	  	          message: '删除成功!'
	  	        });
	  	      })
	  	  
	  	    }).catch(() => {
	  	      this.$message({
	  	        type: 'info',
	  	        message: '已取消删除'
	  	      });
	  	    });
	  	  },
  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>