
<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
</style><!-- /* '精品课程信息 */ -->
<template>
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          <el-button style="height: 38px" type="primary" @click="dc">导出</el-button>
		  
		   <el-descriptions-item>
		        <template slot="label">
		          <i class="el-icon-user"></i>
		          用户名
		        </template>
		        kooriookami
		      </el-descriptions-item>
		  
		  
          <el-table :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;"  ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
			<el-table-column type="selection"  width="40px"></el-table-column>
			<el-table-column align="center" label="ID" 				prop="instrumentId" 	></el-table-column>
            <el-table-column align="center" label="仪器编号" 		prop="instrumentBh" 	></el-table-column>
            <el-table-column align="center" label="仪器名称" 		prop="instrumentName" 	> </el-table-column>
			<el-table-column align="center" label="仪器类型" 		prop="instrumentType" 	></el-table-column>      
            <el-table-column align="center" label="购买时间" 		prop="buyTime"></el-table-column>       
			<el-table-column align="center" label="购买价格" 		prop="buyPrice" 		></el-table-column>      
			<el-table-column align="center" label="使用范围" 		prop="useRange"></el-table-column>      
			<el-table-column align="center" label="管理员" 			prop="adminName" 		></el-table-column>
			<el-table-column align="center" label="维护记录" 		prop="whRecord" 		></el-table-column>
			<el-table-column align="center" label="使用记录" 		prop="useRecord" 		></el-table-column>
            <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
				  <div>
					<el-button @click="handleupdateup(scope.row.instrumentId)" class="ProjectInfo" type="danger">项目信息</el-button>
					<el-button @click="handleupdate(scope.row)" class="ProjectGrod" type="danger">项目组成员</el-button>  
				  </div>
                
				 
				 <div style="margin-top: 10px;">
				 	<el-button @click="handleupdateup1(scope.row.instrumentId)" class="ProjectInfo" type="danger" >审核信息</el-button>
				 	<el-button @click="open(scope.row)" class="ProjectGrod" type="danger">删除</el-button> 
				 </div>
				
              </template>
            </el-table-column>
          </el-table>
		 <div style="margin-top: 20px">
		 	<el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
		 	 <el-button @click="toggleSelection()">取消选择</el-button>
		 </div> 
		<!--分页组件-->
		<el-pagination :current-page="currentPage" :page-size="pagesize" :page-sizes="[5, 10, 20, 50]"
		               :total="total" class="pagestrip" layout="total, sizes, prev, pager, next, jumper"
		               style="margin-top: 20px; float:right" @size-change="handleSizeChange" @current-change="handleCurrentChange">
		</el-pagination>
		  
		  
        </el-card>
      </div>
    </el-col>
	
	
	<el-dialog
	  title="项目信息"
	  :visible.sync="dialogFormVisiblegetup"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="仪器编号:" :label-width="formLabelWidth">
				   <el-input v-model="form.instrumentBh" autocomplete="off"></el-input>
				 </el-form-item>
	  			  <el-form-item label="仪器名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.instrumentName" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="仪器类型:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.instrumentType" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="购买时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.buyTime" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="购买价格:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.buyPrice" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="使用范围:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.useRange" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="管理员:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.adminName" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="维护记录:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.whRecord" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="使用记录:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.useRecord" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
				  <el-form-item label="审核状态:" :label-width="formLabelWidth">
				    <el-input v-model="form.sh" autocomplete="off"></el-input>
				  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="handle()">取 消</el-button>
	    
	  </span>
	</el-dialog>
	
	
	<el-dialog
	  title="项目信息"
	  :visible.sync="upme"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="仪器编号:" :label-width="formLabelWidth">
				   <el-input v-model="form.instrumentBh" autocomplete="off"></el-input>
				 </el-form-item>
	  			  <el-form-item label="仪器名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.instrumentName" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="仪器类型:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.instrumentType" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="购买时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.buyTime" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="购买价格:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.buyPrice" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="使用范围:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.useRange" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="管理员:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.adminName" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="维护记录:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.whRecord" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="使用记录:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.useRecord" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
				  
				  <el-form-item label="审核状态:" :label-width="formLabelWidth">
				    <el-select v-model="form.sh" clearable placeholder="请选择" style="width:250px;float: right">
				      <el-option v-for="item in zct" :key="item.id" :label="item.shname" :value="item.id"
				                 clearable>
				      </el-option>
				    </el-select>
				  </el-form-item>
				  
				  
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="handleupqx()">取 消</el-button>
	    <el-button type="primary" @click="hanleupqd()">确 定</el-button>
	  </span>
	</el-dialog>
	
	
	
	
	
 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
		//分页
	currentPage:1,
	pagesize:5,
	total:0,
	  zct:[{id:1,shname:"待审核"},{id:2,shname:"审核通过"},{id:3,shname:"审核失败"}],
      formLabelWidth: "130px",//控制每一个名称加输入框的宽度
      tableData: [
	  ],
	  FormData:[],
	  multiplerTable:[],
	  dialogFormVisiblegetup: false,//编辑弹窗是否可见false 不可见 true 可见
	  upme:false,
	  radio: 0,
	    form: {},
    };
  },
  created() {
    this.sele();
  },
  methods: {
	  
	  
	  
	  
	  
	  
	  
	  
	   dc(){
	  		 axios({
	  		        url: '/syyq/yqexportZzToExcel',
	  		        method: 'GET',
	  		        responseType: 'blob'
	  		      })
	  		      .then(response => {
	  		        const url = window.URL.createObjectURL(new Blob([response.data]));
	  		        const link = document.createElement('a');
	  		        link.href = url;
	  		        link.setAttribute('download', '实验仪器.xlsx');
	  		        document.body.appendChild(link);
	  		        link.click();
	  		        document.body.removeChild(link);
	  		      })
	  		      .catch(error => {
	  		        // 处理错误
	  		      });
	  	  },
	  
	  
	  toggleSelection(rows) {
		         if (rows) {
		           rows.forEach(row => {
		             this.$refs.multipleTable.toggleRowSelection(row);
		           });
		         } else {
		           this.$refs.multipleTable.clearSelection();
		         }
		       },
		   handleSelectionChange(val) {
		     this.multipleSelection = val;
		   },  
	 //分页条
	 handleSizeChange(val) {
	   this.pagesize = val;
	   this.sele();
	 },
	 handleCurrentChange(val) {
	   this.currentPage = val;
	   this.sele();
	 }, 
	  
	  sele(){
		  axios.get('/syyq/yqPage',{
			  params:{
				  currentPage: this.currentPage,
				  pageSize: this.pagesize,
			  }
		  }).then((res) => {
			  this.tableData = res.data.list
			  this.total = res.data.total
			  console.log(res.data.total)
			  console.log(res.data.list)

		  });
	  },
	  handleupdateup(id){
		  console.log("打印的id")
		  console.log(id)
	  	this.dialogFormVisiblegetup=true;
	  	axios.get('/syyq/getById'+"?id="+id).then((res)=>{
	  		console.log(id);
	  		this.form=res.data;
	  		console.log(res.data);	
	  	})
	  },
	  //取消查看窗口
	  handle(){
	  	this.dialogFormVisiblegetup=false;
	  },

	  //弹出查看窗口
	  handleupdate(row){ 
	  	this.upme=true;
	  	var id=row.instrumentId;
	  	axios.get('/syyq/getById'+"?id="+id).then((res)=>{
	  		console.log(id);
	  		this.form=res.data;
	  		console.log(res.data);	
	  	})
	  	
	  },
	  //确定编辑
	  hanleupqd(){
	  	this.upme=false;
		axios.put('/syyq/up',this.form).then((res)=>{
					  console.log(res)
			if(res.data.code==0){
						console.log("修改成果")
						console.log(res.data)
				this.$message.success(res.data.message);
				this.sele();
			}else{
				this.$message.error(res.data.message);
			}
		})
	  	
	  },
	  //取消编辑窗口
	  handleupqx(){
	  	this.upme=false;
	  },
	  
	  
	  
	  //是否删除
	  open(row) {
		  console.log("234567890-")
		  console.log(row)
	    this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
	      confirmButtonText: '确定',
	      cancelButtonText: '取消',
	      type: 'warning'
	    }).then(() => {
			var id=row.instrumentId
	      axios.delete('/syyq/delyq?id='+id).then((res) => {
	        this.sele();
	        this.$message({
	          type: 'success',
	          message: '删除成功!'
	        });
	      })
	  
	    }).catch(() => {
	      this.$message({
	        type: 'info',
	        message: '已取消删除'
	      });
	    });
	  },
	  

  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>