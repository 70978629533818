<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
</style>
<template>
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          <!-- <el-button style="height: 38px" type="primary"
                     >导出
          </el-button> -->
		  </el-descriptions-item>
          <el-table :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;">
            <el-table-column align="center" label="用户id" prop="id" ></el-table-column>
			<el-table-column align="center" label="用户名" prop="name" ></el-table-column>      
            <el-table-column align="center" label="密码" prop="password" > </el-table-column>
            <el-table-column align="center" label="用户注册时间" prop="createTime"></el-table-column>
			<el-table-column align="center" label="用户修改时间" prop="uptime"></el-table-column>
			<el-table-column align="center" label="修改人" prop="uppeople"></el-table-column>
           <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
                <el-button @click="editPasswordDialog = true" class="ProjectInfo" type="danger">修改信息</el-button>
				
              </template>
            </el-table-column>
          </el-table> 
		  
		  
		  
		  
		  <!-- 修改密码 dialog -->
		  <el-dialog :before-close="closeEditPassword" :close-on-click-modal="false" :visible.sync="editPasswordDialog" title="修改密码"
		             width="30%">
		    <el-form ref="editPasswordForm" :model="editPasswordForm" :rules="editPasswordRule"
		             class="demo-form-inline" label-width="90px">
		  	<el-form-item label="用户名:" prop="oldname">
		  	  <el-input v-model="editPasswordForm.oldname" placeholder="请输入"/>
		  	</el-form-item>
		      <!-- <el-form-item label="原密码:" prop="oldPassword">
		        <el-input v-model="editPasswordForm.oldPassword" placeholder="请输入原密码"/>
		      </el-form-item> -->
		      <el-form-item label="新密码:" prop="newPassword">
		        <el-input type="password" v-model="editPasswordForm.newPassword" placeholder="请输入新密码"/>
		      </el-form-item>
		      <el-form-item label="确认密码:" prop="confirmPassword">
		        <el-input type="password" v-model="editPasswordForm.confirmPassword" placeholder="请确认密码"/>
		      </el-form-item>
		      <el-form-item>
		        <el-button size="small" @click="closeEditPassword()">取 消</el-button>
		        <el-button size="small" type="primary" @click="editPassword()">保 存</el-button>
		      </el-form-item>
		    </el-form>
		  </el-dialog>
		  
		  
		  
		  
		  
        </el-card>
		
		
		
		
		
		
		
		
		
		
		
      </div>
    </el-col>
 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {

      formLabelWidth: "12",
      tableData: [],
	  editPasswordDialog: false,
	  editPasswordForm: {
	  	//oldPassword: "",
	  	newPassword: "",
	  	confirmPassword: "",
	  	oldname:"",
	  	
	  },
	  editPasswordRule: {
	  	oldname:[{
	  		required: true,
	  		message:"请输入用户名",
	  		trigger:"blur"
	  	},],
	  /* 	oldPassword: [{
	  		required: true,
	  		message: "请输入原密码",
	  		trigger: "blur"
	  	}, ], */
	  	newPassword: [{
	  		required: true,
	  		message: "请输入新密码",
	  		trigger: "blur"
	  	}, ],
	  	confirmPassword: [{
	  		required: true,
	  		message: "请确认新密码",
	  		trigger: "blur"
	  	}, ],
	  },

    };
  },
  mounted() {
  	document.body.style.zoom = 0.948
  	this.userNames = sessionStorage.getItem("name")
  	this.editPasswordForm.oldname = sessionStorage.getItem("name")
  },

  created() {
    this.sele();
	this.arr = localStorage.getItem('arr')
  },
  methods: {
	  // 修改密码
	  editPassword() {
	  	axios.get('/huser/upuser',{
	  		params:{
	  			password :this.editPasswordForm.newPassword,
	  			name :this.editPasswordForm.oldname,
	  			uppeople:this.editPasswordForm.oldname
	  		}
	  	}).then((res)=>{
	  		if(this.editPasswordForm.newPassword==this.editPasswordForm.confirmPassword){
	  			console.log(res)
	  			this.$message.success(res.data.message);
	  			sessionStorage.clear();
	  			this.$router.push("/")
	  		}else{
	  			this.$message.error("两次密码不一致");
	  		}
	  		
	  			
	  		
	  	})
	  },
	  // 取消关闭密码
	  closeEditPassword() {
	  	this.editPasswordDialog = false;
	  	// 坑：resetFields 方法只能重置带有 props 属性的元素
	  	this.$refs.editPasswordForm.resetFields();
	  },
	
	  sele(){
		  axios.get('/huser/selectall',{
		  }).then((res) => {
		    this.tableData = res.data
			
			console.log("0000000")
			
		    console.log(this.tableData)
		  });
	  },
  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>