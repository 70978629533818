<template>
	<div>
		<el-container class="home-container">
			<!-- header -->
			<!-- 菜单 -->
			<el-aside style="width: 300px; background-color: rgb(234, 243, 253);">
				<div style="background-color: #F8FCFF; background-color: rgb(234, 243, 253);"> <!-- margin-top: 30px; margin-left: 40px; -->
					<el-row>
					
						 <div class="image-container">
						        <img src="../img/微信图片_20240801162041-removebg-preview.png" class="image" alt="Description of the image">
						    </div>
					</el-row>
				</div>


				<el-menu :collapse="isCollapse" active-text-color="sky-bule" class="el-menu-vertical-demo" router
					:unique-opened="true" @close="handleClose" @open="handleOpen" style=" background-color: #EAF3FD;">
										
					<el-menu-item index="loginuser">
						<i class="el-icon-notebook-1"></i>
						用户审核
					</el-menu-item>
					
					
					<el-menu-item index="xmsh">
						<i class="el-icon-notebook-2"></i>
						项目审核
					</el-menu-item>
					
					
					
					
					
					
					
					
					
					
					
					
					
					
					 
					    <el-menu style="background-color: #EAF3FD;" router>
					      <el-submenu index="1">
					        <template slot="title">
					          <i class="el-icon-s-operation"></i>
					          <span>项目列表</span>
					        </template>
					        <el-submenu index="1-1">
					          <template slot="title">科研项目</template>
					         <el-menu-item index="1-1-1">科研项目信息</el-menu-item>
					         <el-menu-item index="1-1-2">项目结题信息</el-menu-item>
					         <el-menu-item index="1-1-3">科研经费管理</el-menu-item>
					         <el-menu-item index="1-1-4">项目延期申请</el-menu-item>
					         <el-menu-item index="1-1-5">项目变更申请</el-menu-item>
					        </el-submenu>
							
							<el-menu-item index="1-2">
								<span slot="title">合同管理</span>
							</el-menu-item>
							
							<el-submenu index="1-3">
							  <template slot="title">科研成果管理</template>
							 <el-menu-item index="1-3-1">科技论文信息</el-menu-item>
							 <el-menu-item index="1-3-2">著作信息</el-menu-item>
							 <el-menu-item index="1-3-3">主讲省级以上精品课程</el-menu-item>
							 <el-menu-item index="1-3-4">获奖信息</el-menu-item>
							 <el-menu-item index="1-3-5">专利信息</el-menu-item>
							 <el-menu-item index="1-3-6">科技成果应用</el-menu-item>
							 <el-menu-item index="1-3-7">科技成果转化</el-menu-item>
							 <el-menu-item index="1-3-8">学术交流</el-menu-item>
							 <el-menu-item index="1-3-9">学术任职</el-menu-item>
							</el-submenu>
							
							
							<el-submenu index="1-4">
							  <template slot="title">科研平台/基地/中心</template>
							 <el-menu-item index="1-4-1">研究基地</el-menu-item>
							 <el-menu-item index="1-4-2">科技平台</el-menu-item>
							 <el-menu-item index="1-4-3">临床医学研究中心</el-menu-item>
							</el-submenu>
							
							<el-submenu index="1-5">
							  <template slot="title">人类资源采集</template>
							 <el-menu-item index="1-5-1">资源采集许可</el-menu-item>
							 <el-menu-item index="1-5-2">研究合作许可</el-menu-item>
							</el-submenu>
							
							<el-submenu index="1-6">
							  <template slot="title">学术信息</template>
							 <el-menu-item index="1-6-1">学生基本信息</el-menu-item>
							 <el-menu-item index="1-6-2">学生获奖信息</el-menu-item>
							 <el-menu-item index="1-6-3">学生科技论文</el-menu-item>
							 <el-menu-item index="1-6-4">学生学术论文</el-menu-item>
							 <el-menu-item index="1-6-5">导师基本信息</el-menu-item>
							</el-submenu>
							
							<el-menu-item index="1-7">
								<span slot="title">数据库管理</span>
							</el-menu-item>
							
							<el-submenu index="1-8">
							  <template slot="title">实验室管理</template>
							 <el-menu-item index="1-8-1">实验室基本信息</el-menu-item>
							 <el-menu-item index="1-8-2">实验仪器基本信息</el-menu-item>
							</el-submenu>
							
							<el-menu-item index="1-9">
								<span slot="title">知识产权管理</span>
							</el-menu-item>
							
							<el-submenu index="1-10">
							  <template slot="title">专病队列数据库</template>
							 <el-menu-item index="1-10-1">项目基本信息</el-menu-item>
							 <el-menu-item index="1-10-2">人口学信息</el-menu-item>
							 <el-menu-item index="1-10-3">随访阶段</el-menu-item>
							 <el-menu-item index="1-10-4" disabled>住院信息</el-menu-item>
							 <el-menu-item index="1-10-5" disabled>病史信息</el-menu-item>
							 <el-menu-item index="1-10-6" disabled>CRE表</el-menu-item>
							 <el-menu-item index="1-10-7" disabled>随访问卷</el-menu-item>
							</el-submenu>
							
							
					       </el-submenu>
					    </el-menu>
					 
					 
					<el-menu-item index="11">
						<i class="el-icon-s-goods"></i>
						<span slot="title">修改密码</span>
					</el-menu-item>
					
					
				</el-menu>
			</el-aside>



			<!-- 修改密码 dialog -->
			<el-dialog :before-close="closeEditPassword" :close-on-click-modal="false" :visible.sync="editPasswordDialog" title="修改密码"
			           width="30%">
			  <el-form ref="editPasswordForm" :model="editPasswordForm" :rules="editPasswordRule"
			           class="demo-form-inline" label-width="90px">
				<el-form-item label="用户名:" prop="oldname">
				  <el-input v-model="editPasswordForm.oldname" placeholder="请输入"/>
				</el-form-item>
			    <!-- <el-form-item label="原密码:" prop="oldPassword">
			      <el-input v-model="editPasswordForm.oldPassword" placeholder="请输入原密码"/>
			    </el-form-item> -->
			    <el-form-item label="新密码:" prop="newPassword">
			      <el-input type="password" v-model="editPasswordForm.newPassword" placeholder="请输入新密码"/>
			    </el-form-item>
			    <el-form-item label="确认密码:" prop="confirmPassword">
			      <el-input type="password" v-model="editPasswordForm.confirmPassword" placeholder="请确认密码"/>
			    </el-form-item>
			    <el-form-item>
			      <el-button size="small" @click="closeEditPassword()">取 消</el-button>
			      <el-button size="small" type="primary" @click="editPassword()">保 存</el-button>
			    </el-form-item>
			  </el-form>
			</el-dialog>
			
			
			
			<el-container style="overflow: auto">

				<!-- 修改用户信息的 -->
				<el-main>
					<el-card style="border-radius: 10px;">
						<!-- 科研项目管理后台管理系统 -->
						<div @click="editPasswordDialog = true">
							<i class="el-icon-s-custom"></i>
							{{userNames}}
						</div>
						
						<i class="el-icon-switch-button" style="font-size: 20px; float: right;position: relative; top: -18px;" @click="logout()"></i>

					</el-card>
					
					<div style="height: 10px;">
					</div>
					<router-view />
					<!-- <div style="color:#929293; opacity: 0.8; margin: 0px auto;width: 250px;position: relative;top: 650px" ><p>羽链数据</p></div> -->
				</el-main>
				<el-footer style="color:#929293; opacity: 0.8; margin: 20px auto;width: 250px;position: relative;">技术支持：羽链数据</el-footer>
			</el-container>


		</el-container>
	</div>
</template>

<script>
	/*import Breadcrumb from "../components/Breadcrumb.vue";*/

import axios from 'axios';

	export default {
		data() {
			return {
				arr: [],
				userNames: "",
				avatar: require("../img/logo.png"),
				isCollapse: false,
				editPasswordDialog: false,
				editPasswordForm: {
					//oldPassword: "",
					newPassword: "",
					confirmPassword: "",
					oldname:"",
					
				},
				editPasswordRule: {
					oldname:[{
						required: true,
						message:"请输入用户名",
						trigger:"blur"
					},],
				/* 	oldPassword: [{
						required: true,
						message: "请输入原密码",
						trigger: "blur"
					}, ], */
					newPassword: [{
						required: true,
						message: "请输入新密码",
						trigger: "blur"
					}, ],
					confirmPassword: [{
						required: true,
						message: "请确认新密码",
						trigger: "blur"
					}, ],
				},
			};
		},
		mounted() {
			document.body.style.zoom = 0.948
			this.userNames = sessionStorage.getItem("name")
			this.editPasswordForm.oldname = sessionStorage.getItem("name")
		},
		created() {
			this.arr = localStorage.getItem('arr')
		},

		methods: {

			handleOpen(key, keyPath) {},
			handleClose(key, keyPath) {},
			// 修改密码
			editPassword() {
				axios.get('/huser/upuser',{
					params:{
						password :this.editPasswordForm.newPassword,
						name :this.editPasswordForm.oldname,
						uppeople:this.editPasswordForm.oldname
					}
				}).then((res)=>{
					if(this.editPasswordForm.newPassword==this.editPasswordForm.confirmPassword){
						console.log(res)
						this.$message.success(res.data.message);
						sessionStorage.clear();
						this.$router.push("/")
					}else{
						this.$message.error("两次密码不一致");
					}
					
						
					
				})
			},
			// 取消关闭密码
			closeEditPassword() {
				this.editPasswordDialog = false;
				// 坑：resetFields 方法只能重置带有 props 属性的元素
				this.$refs.editPasswordForm.resetFields();
			},
			// 退出系统
			logout() {
				this.$confirm("确定要退出系统吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						// 清除缓存
						localStorage.clear();
						sessionStorage.clear();
						this.$router.push("/");
					})
					.catch(() => {
						return false;
					});
			},
		},
	};
</script>

<style scoped>
	    .image-container {
	            
	            display: inline-block; /* 使容器只包裹图片的大小 */
				width: 80px;
				height: 80px;
	            padding: 10px; /* 可选：添加一些内边距 */
				position: relative;
				left: 30px;
	        }
	        .image {
	            display: block; /* 使图片成为块级元素 */
	            max-width: 100%; /* 确保图片不会超出容器 */
	            height: auto; /* 保持图片的宽高比 */
	        }
	.el-menu-vertical-demo {
		/* background-color: #679EB8; */
	}

	.home-container {
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		position: absolute;
		background: #f2f3f5;
	}

	.el-header {
		background: #679EB8;
		/* padding: 0 10px; */
		width: 100%;
		overflow: hidden;
		text-align: center;
	}

	.system-name {
		color: #fff;
		font-size: 24px;
		width: 300px;
		margin-top: 14px;


	}

	.el-aside {
		background: #ebe7e4;
		width: 200px !important;
	}
</style>