<style>
	.ProjectInfo{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod{
		width: 120px;
		/* float: right; */
	}
	.ProjectInfo1{
		/* float: left;
		text-align: center; */
		width: 100px;
	}
	.ProjectGrod2{
		width: 120px;
		/* float: right; */
	}
</style>
<template>
  <div style="margin:auto;width: 100%;">
    <el-col>
      <div class="grid-content bg-purple">
        <el-card shadow="always" style="height: auto;border-radius: 20px;">
          <el-button style="height: 38px" type="primary" @click="dc">导出</el-button>
		  
		   <el-descriptions-item>
		        <template slot="label">
		          <i class="el-icon-user"></i>
		          用户名
		        </template>
		        kooriookami
		      </el-descriptions-item>
		  
		  
          <el-table  row-key="id" :data="tableData" :header-cell-style="{ background: 'skyblue', color: '#ffffff',}" :border="true"
                    style="width: 100%;margin-top: 24px;border-radius: 8px;"   ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
					   <el-table-column type="selection"  width="40px" :reserve-selection="true"></el-table-column>
            <el-table-column align="center" label="编号" prop="id" width="90"></el-table-column>
			<el-table-column align="center" label="年度" prop="time" width="100"></el-table-column>      
            <el-table-column align="center" label="项目编号" prop="bh" width="120px"> </el-table-column>
            <el-table-column align="center" label="项目名称" prop="xmName"></el-table-column>       
			<el-table-column align="center" label="项目组负责人名称" prop="name" width="150px"></el-table-column>      
			<el-table-column align="center" label="项目来源" prop="lyname"></el-table-column>      
			<el-table-column align="center" label="项目级别" prop="level" width="100px"></el-table-column>      
			<el-table-column align="center" label="项目类型" prop="typename" width="100px"></el-table-column>      
			<el-table-column align="center" label="立项部门" prop="source"></el-table-column>       
			<el-table-column align="center" label="项目起止时间" prop="xmDuration"></el-table-column>

            <el-table-column align="center" label="操作" width="300">
              <template slot-scope="scope">
				<div>
					<el-button @click="handleupdateup(scope.row.id)" class="ProjectInfo" type="danger">项目信息</el-button>
					<el-button @click="handleupdate(scope.row.bh)" class="ProjectGrod" type="danger">项目组成员</el-button>
				</div>
				<div style="margin-top: 10px;">
					<el-button @click="upmessageb(scope.row)" class="ProjectInfo1" type="danger">编辑信息</el-button>
					<el-button @click="open(scope.row.id)" class="ProjectGrod" type="danger">删除</el-button>
				</div>
              </template>
            </el-table-column>
          </el-table>
		 <div style="margin-top: 20px">
		     <el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
		     <el-button @click="toggleSelection()">取消选择</el-button>
		</div>
		  
		<!--分页组件-->
		<el-pagination :current-page="currentPage" :page-size="pagesize" :page-sizes="[5, 10, 20, 50]"
		               :total="total" class="pagestrip" layout="total, sizes, prev, pager, next, jumper"
		               style="margin-top: 20px; float:right" @size-change="handleSizeChange" @current-change="handleCurrentChange">
		</el-pagination>
		  
		  
        </el-card>
      </div>
    </el-col>
	
	
	<el-dialog
	  title="项目信息"
	  :visible.sync="dialogFormVisiblegetup"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="年度:" :label-width="formLabelWidth">
				   <el-input v-model="form.time" autocomplete="off" disabled></el-input>
				 </el-form-item>
	  			  <el-form-item label="项目编号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.bh" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="项目名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.xmName" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="科室:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ks" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="项目组负责人:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.name" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="性别:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.sex" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="出生日期:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.birthday" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="职称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.zc" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="职务:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.zw" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="分工:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.name" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="工作时长:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.workTime" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="项目来源:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.lyname" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="项目级别:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.level" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="项目类型:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.typename" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="立项部门:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.source" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="拨款经费(万元):" :label-width="formLabelWidth">
	  			    <el-input v-model="form.bkPrice" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="配套经费:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ptPrice" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="项目开始时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.xmStater" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="项目结束时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.xmEnd" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="资金下达时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.priceStarter" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="任务书签订时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.signedTime" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
					
	  			  <el-form-item label="是否为辅导项目:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.fdXm" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="是否横向满足:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.hx" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="涉及人体生物批号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.creature" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="涉及动物伦理批号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.animal" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="handleupqx()">取 消</el-button>
	    <!-- <el-button type="primary" @click="hanleupqd()">确 定</el-button> -->
	  </span>
	</el-dialog>
	
	
	<el-dialog
	  title="编辑信息"
	  :visible.sync="upmessage"
	  width="55%"
	  center>
	  <!-- width : 对话框的大小-->
	  <el-form :inline=true :model="form" label-position="right"><!-- label-position  文字的对齐方式  right left top -->
				 <el-form-item label="年度:" :label-width="formLabelWidth">
				   <el-input v-model="form.time" autocomplete="off"></el-input>
				 </el-form-item>
	  			  <el-form-item label="项目编号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.bh" autocomplete="off" disabled></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="项目名称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.xmName" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="科室:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ks" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="项目组负责人:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.name" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="性别:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.sex" autocomplete="off"></el-input>
					<!-- <el-select v-model="form.sex" clearable placeholder="请选择" style="width:200px;float: right">
					  <el-option v-for="item in sextype" :key="item.id" :label="item.sexname" :value="item.id"
					             clearable>
					  </el-option>
					  </el-option>
					</el-select> -->
	  			  </el-form-item>
	  			  <el-form-item label="出生日期:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.birthday" autocomplete="off"></el-input>
					
	  			  </el-form-item>
	  			  <el-form-item label="职称:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.zc" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="职务:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.zw" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="分工:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.name" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="工作时长:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.workTime" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="项目来源:" :label-width="formLabelWidth">
	  			    <!-- <el-input v-model="form.lyname" autocomplete="off"></el-input> -->
					<el-select v-model="form.xmLy" clearable placeholder="请选择" style="width:250px;float: right" @change="jcxx">
					  <el-option v-for="item in collage" :key="item.id" :label="item.lyname" :value="item.id"
					             clearable>
					  </el-option>
					</el-select>
	  			  </el-form-item>
				
	  			  <el-form-item label="项目级别:" :label-width="formLabelWidth">
	  			    <!-- <el-input v-model="form.level" autocomplete="off"></el-input> -->
					<el-select v-model="form.xmJb" clearable placeholder="请选择" style="width:250px;float: right" @change="jb">
					  <el-option v-for="item in jbtype" :key="item.id" :label="item.level" :value="item.id"
					             clearable>
					  </el-option>
					</el-select>
	  			  </el-form-item>
				  
	  			  <el-form-item label="项目类型:" :label-width="formLabelWidth">
					<el-select v-model="form.xmType" clearable placeholder="请选择" style="width:250px;float: right" @change="lxty">
					  <el-option v-for="item in lxtype" :key="item.id" :label="item.name" :value="item.id"
					             clearable>
					  </el-option>
					</el-select>
	  			  </el-form-item>
	  			  <el-form-item label="立项部门:" :label-width="formLabelWidth">
	  			    <!-- <el-input v-model="form.source" autocomplete="off"></el-input> -->
					<el-select v-model="form.xmBm" clearable placeholder="请选择" style="width:250px;float: right" @change="lxb">
					  <el-option v-for="item in lxbm" :key="item.id" :label="item.source" :value="item.id"
					             clearable>
					  </el-option>
					</el-select>
	  			  </el-form-item>
	  			  <el-form-item label="拨款经费(万元):" :label-width="formLabelWidth">
	  			    <el-input v-model="form.bkPrice" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="配套经费:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.ptPrice" autocomplete="off"></el-input>
	  			  </el-form-item>
				  
	  			  <el-form-item label="项目开始时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.xmStater" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="项目结束时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.xmEnd" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="资金下达时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.priceStarter" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="任务书签订时间:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.signedTime" autocomplete="off"></el-input>
	  			  </el-form-item>
					
	  			  <el-form-item label="是否为辅导项目:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.fdXm" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="是否横向满足:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.hx" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="涉及人体生物批号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.creature" autocomplete="off"></el-input>
	  			  </el-form-item>
	  			  <el-form-item label="涉及动物伦理批号:" :label-width="formLabelWidth">
	  			    <el-input v-model="form.animal" autocomplete="off"></el-input>
	  			  </el-form-item>
	   </el-form>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="upbuttonqx()">取 消</el-button>
	    <el-button type="primary" @click="upbuttonqd()">确 定</el-button>
	  </span>
	</el-dialog>
	
	
	
	
	
	
	
	<el-dialog title="查看项目成员信息" :visible.sync="dialogFormVisibleget">
		
		<el-table
		  :data="FormData"
		  style="width: 100%">
		  
		  <el-table-column
		    prop="id"
		    label="编号"
		    >
		  </el-table-column>
		  
		  <el-table-column
		    prop="ks"
		    label="科室"
		    >
		  </el-table-column>
		  
		  <el-table-column
		    prop="name"
		    label="名称">
		  </el-table-column>
		  
		  <el-table-column
		    prop="sex"
		    label="性别"
			>
		  </el-table-column>
		  
		  <el-table-column
		    prop="birthday"
		    label="出生年月">
		  </el-table-column>
		  
		  <el-table-column
		    prop="zc"
		    label="职称">
		  </el-table-column>
		  
		  <el-table-column
		    prop="zw"
		    label="职务">
		  </el-table-column>
		
		  <el-table-column
		    prop="fg"
		    label="分工">
		  </el-table-column>
		  
		  <el-table-column
		    prop="workTime"
		    label="工作时长">
		  </el-table-column>

		</el-table>
		

	  <div slot="footer" class="dialog-footer">
	    <el-button @click="handle()">取 消</el-button>
	    
	  </div>
	</el-dialog>
 
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
		//分页
	currentPage:1,
	pagesize:5,
	total:0,
	collage:[],//项目来源
	jbtype:[],//级别
	lxtype:[],
	lxbm:[],
	sextype:[
		{id:0,sexname:"女"},
		{id:1,sexname:"男"}
	],
      formLabelWidth: "130px",//控制每一个名称加输入框的宽度
      tableData: [],
	  multiplerTable:[],
	  FormData:[],
	  dialogFormVisiblegetup: false,//查看弹窗是否可见false 不可见 true 可见
	  dialogFormVisibleget:false,//查看项目成员
	  upmessage:false,//编辑项目信息弹窗
	  form: {},
    };
  },
  created() {
    this.sele();

  },
  methods: {
	  
	  	  dc(){
	  		 axios({
	  		        url: '/form/xmexportZzToExcel',
	  		        method: 'GET',
	  		        responseType: 'blob'
	  		      })
	  		      .then(response => {
	  		        const url = window.URL.createObjectURL(new Blob([response.data]));
	  		        const link = document.createElement('a');
	  		        link.href = url;
	  		        link.setAttribute('download', '科研项目信息.xlsx');
	  		        document.body.appendChild(link);
	  		        link.click();
	  		        document.body.removeChild(link);
	  		      })
	  		      .catch(error => {
	  		        // 处理错误
	  		      });
	  	  },
	  
	  //是否删除
	  	  open(id) {
	  	    this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
	  	      confirmButtonText: '确定',
	  	      cancelButtonText: '取消',
	  	      type: 'warning'
	  	    }).then(() => {
	  	      axios.delete('/form/delxm?id='+id).then((res) => {
	  	        this.sele();
	  	        this.$message({
	  	          type: 'success',
	  	          message: '删除成功!'
	  	        });
	  	      })
	  	  
	  	    }).catch(() => {
	  	      this.$message({
	  	        type: 'info',
	  	        message: '已取消删除'
	  	      });
	  	    });
	  	  },
	  
	  
	  
	  //维表
	  jcxx() {
	    axios.get('/wb/xmly').then((res) => {
			console.log(res.data)
	      this.collage = res.data
	    })
	  },
	  jb(){
		 axios.get('/wb/xmjb').then((res) => {
		 	console.log(res.data)
		   this.jbtype = res.data
		 }) 
	  },
	  lxty(){
		  axios.get('/wb/xmtype').then((res) => {
		  	console.log(res.data)
		    this.lxtype = res.data  
		  }) 
	  },
	  lxb(){
		 axios.get('/wb/xmbm').then((res) => {
		 	console.log(res.data)
		   this.lxbm = res.data
		 })  
	  },
	   toggleSelection(rows) {
	          if (rows) {
	            rows.forEach(row => {
	              this.$refs.multipleTable.toggleRowSelection(row);
	            });
	          } else {
	            this.$refs.multipleTable.clearSelection();
	          }
	        },
	    handleSelectionChange(val) {
	      this.multipleSelection = val;
		  console.log("weerty")
		  console.log(val.data)
	    },
	  
	 //分页条
	 handleSizeChange(val) {
	   this.pagesize = val;
	   this.sele();
	 },
	 handleCurrentChange(val) {
	   this.currentPage = val;
	   this.sele();
	 }, 
	sele(){
	  axios.get('/form/getPage',{
		  params:{
			  currentPage: this.currentPage,
			  pageSize: this.pagesize,
		  }
	  }).then((res) => {
		  this.tableData = res.data.list
		  this.total = res.data.total
		  console.log(res.data.total)
	  });
	},
	  //弹出查看窗口
	  handleupdateup(id){
	  	this.dialogFormVisiblegetup=true;
	  	axios.get('/form/getOneP'+"?id="+id).then((res)=>{
	  		console.log(id);
	  		this.form=res.data;
	  		console.log(res.data);	
	  	})
	  	
	  },
	  //点击遍接按钮弹窗
	  upmessageb(row){
		  this.lxty()
		  this.jcxx()
		  this.jb()
		  this.lxb()
		  this.upmessage=true;
		  var id = row.id
		  axios.get('/form/getOneP'+"?id="+id).then((res)=>{
		  	console.log(id);
		  	this.form=res.data;
			console.log("根据id拆线呢出来的数据")
			console.log(res.data);	
			// this.lxtypeid=res.data.xmType
			// this.bmid=res.data.xmBm
			// this.jbId=res.data.xmJb;
			// this.sexid=res.data.sex
		  	
		  })
	  },
	  upbuttonqx(){
		  this.upmessage=false;
	  },
	  upbuttonqd(){
		  this.upmessage=false;
		  axios.put('/form/upxm',this.form).then((res)=>{
			  console.log(res)
		  	if(res.data.code==0){
				console.log("修改成果")
				console.log(res.data)
		  		this.$message.success(res.data.message);
		  		this.sele();
		  	}else{
		  		this.$message.error(res.data.message);
		  	}
		  })
		  
	  },
	  
	  
	  //确定查看
	  // upbutton(){
	  // 	this.dialogFormVisiblegetup=false;
	  //  	axios.put(this.url.updateaudit,this.FormData).then((res)=>{
	  // 		if(res.data.msg){
	  // 			this.$message.success(res.data.msg);
	  // 			this.findPage();
	  // 		}else{
	  // 			this.$message.error(res.data.msg);
	  // 		}
	  // 	})
	  	
	  // }, 
	  //取消查看窗口
	  handleupqx(){
	  	this.dialogFormVisiblegetup=false;
	  },
	  
	  //取消查看项目组成员窗口
	  handle(){
	  	this.dialogFormVisibleget=false;
	  },
	  //弹出查看项目组成员窗口
	  handleupdate(bh){
		  console.log(bh)
	  	this.dialogFormVisibleget=true;
	  	var that=this;
	  	axios.get('/form/xmPeople'+"?bh="+bh).then((res)=>{
	  		console.log(bh);
	  		that.FormData=res.data;
			console.log("项目组成员")
	  		console.log(res.data);	
	  	})
	  	
	  },
	  
	  formatHx(value) {
	      return value === 0 ? '满足' : '不满足';
	    },
	  formatsh(value) {
	  	console.log(value)
	      if (value === 1) {
	        return '已通过';
	      } else if (value === 2) {
	        return '不通过';
	      } else if (value === 0) {
	        return '待审核';
	      } 
	      
	  },
	  /* formatsex(value) {
	      return value === 0 ? '女' : '男';
	  }, */
	  formatfd(value) {
	      return value === 0 ? '是' : '否';
	  },	  
	 /* formatSex(row, column) {
	       // 这里的 row 表示当前行的数据，column 表示当前列的配置
	       // 假设性别为 1 时显示为 "男"，否则显示为 "女"
	       return row.sex === 1 ? '男' : '女';
	   }, */
  },
}
</script>

<style scoped>
/deep/ .el-tree-node__label {
  width: auto;
  font-size: 19px;
}

.tree {
  margin-left: 40px;
}
</style>